import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { TLine } from "../AddCLaimTypes";
import { reducer } from "~/utils/reducer";
import { ICollectiveCorrectionsList, IInvoice } from "~/store/invoices/types";
import { selectCustomer } from "~/store/customer/actions";
import { DictionariesState, DictionaryItem } from "~/store/dictionaries/types";
import { claimCategories } from "../../claimCategoryDefinitions.conf";
import claimTypeMap from "../../claimTypeMap.conf";
import { ClaimCategory, ClaimGroup } from "../../claimTypes";

type TProps = {
  invoicesWithProvision: ICollectiveCorrectionsList[];
  invoicesWithDebitNotes: IInvoice[];
  selectCustomer: typeof selectCustomer;
  dictionaries: DictionariesState;
};

export const useAddClaimState = ({
  invoicesWithProvision,
  invoicesWithDebitNotes,
  selectCustomer,
  dictionaries,
}: TProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [group, setGroup] = useState<ClaimGroup>(ClaimGroup.unset);
  const [invoice, setInvoice] = useState<string>("");
  const [order, setOrder] = useState<string>("");
  const [category, setCategory] = useState<ClaimCategory>(ClaimCategory.unset);
  const [actors, setActors] = useState<any[]>([]);
  const [type, setType] = useState<ClaimGroup>(ClaimGroup.unset);
  const [customer, setCustomer] = useState<string>("");
  const [delivery, setDelivery] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [vin, setVin] = useState<string>("");
  const [bin, setBin] = useState<string>("");
  const [customInvoiceNumber, setCustomInvoiceNumber] = useState("");
  const [pamir, setPamir] = useState<string>("");
  const [vehicleModel, setVehicleModel] = useState<string>("");
  const [rentalCar, setRentalCar] = useState<boolean>(false);
  const [lines, setLines] = useState<TLine[]>([]);
  const [openCollectiveTask, setOpenCollectiveTask] = useState<boolean>(false);
  const [processAutomatically, setProcessAutomatically] =
    useState<boolean>(false);
  const [tableData, setTableData] = React.useReducer(reducer, {
    data: lines,
    direction: null,
    loading: false,
    checkedLine: null,
    selected: null,
    column: null,
  });
  const categoriesWithActors = useMemo(
    () => ["RK1_ZNRE", "RK1_ZRDP", "RK1_ZRVE", "RK2_ZRNF"],
    []
  );

  const changeGroup = useCallback((event: SyntheticEvent, data: any): void => {
    switch (data.value) {
      case ClaimGroup.realtedToInvoice:
        setProcessAutomatically(false);
        setOrder("");
        setDelivery("");
        setCustomer("");
        setCategory(ClaimCategory.unset);
        setTableData({ type: "INIT_TABLE", column: null, data: [] });
        break;
      case ClaimGroup.notRealtedToInvoice:
        setProcessAutomatically(false);
        setOrder("");
        setInvoice("");
        setDelivery("");
        setCategory(ClaimCategory.unset);
        setTableData({ type: "INIT_TABLE", column: null, data: [] });
        break;
      case "RK3":
        setProcessAutomatically(false);
        setInvoice("");
        setDelivery("");
        setCustomer("");
        setCategory(ClaimCategory.unset);
        setTableData({ type: "INIT_TABLE", column: null, data: [] });
        break;
      case ClaimGroup.provision:
        setProcessAutomatically(false);
        setOrder("");
        setInvoice("");
        setDelivery("");
        setCategory(ClaimCategory.depositReturn);
        setTableData({ type: "INIT_TABLE", column: null, data: [] });
        setProcessAutomatically(true);
        break;
      case ClaimGroup.withoutInvoice:
        setProcessAutomatically(false);
        setOrder("");
        setInvoice("");
        setDelivery("");
        setCategory(ClaimCategory.unset);
        setTableData({ type: "INIT_TABLE", column: null, data: [] });
        setProcessAutomatically(true);
        break;
      default:
        break;
    }
    setLines([]);
    setGroup(data.value);
    setActors([]);
  }, []);

  const resetAfterSearchType = useCallback(() => {
    setInvoice("");
    setTableData({ type: "INIT_TABLE", column: null, data: [] });
    setLines([]);
  }, []);

  const isDFSCategoryOrigin = useCallback((line: any): boolean => {
    return line.categoryOrigin && ["2", "4", "5"].includes(line.categoryOrigin);
  }, []);

  const changeInvoice = useCallback(
    (event: SyntheticEvent, data: any): void => {
      setInvoice(data.value);
      const invoicesList =
        group === ClaimGroup.provision
          ? invoicesWithProvision
          : invoicesWithDebitNotes;
      const foundInvoice = invoicesList.find(
        (invoice) => invoice.invoiceNo === data.value
      );
      let invoiceLines = foundInvoice?.invoiceLines || [];
      if (group === ClaimGroup.provision) {
        const noProvisionFilteredLines = invoiceLines.filter((line, index) => {
          if (
            index !== invoiceLines.length - 1 &&
            line.provisionId &&
            line.provisionId === invoiceLines[index + 1].referenceId
          ) {
            return line;
          } else {
            return false;
          }
        });
        invoiceLines = noProvisionFilteredLines;
      }
      // setAllLines(invoiceLines)
      // @ts-ignore: undefined possible no chance
      const linesToSet = invoiceLines;
      // @ts-ignore: undefined possible no chance
      const mappedLines = linesToSet.map((line: TLine) => {
        return {
          lineNumber: line.lineNumber,
          referenceId: line.referenceId,
          quantity: group === ClaimGroup.provision ? 1 : line.quantity,
          quantityOrgin:
            line.quantity || line.quantityOrgin || line.orderedQuantity,
          orderedQuantity: line.orderedQuantity,
          orderNumber: line.orderNumber,
          quantityCopy: line.quantity,
          packingForceQuantity: line.packingForceQuantity || 1,
          selected: false,
          pvpPrice: isDFSCategoryOrigin(line) ? line.pvpPrice : undefined,
          pnrPrice: isDFSCategoryOrigin(line) ? line.pnrPrice : undefined,
          packageNumber: line.packageNumber ? [line.packageNumber] : undefined,
        };
      });
      foundInvoice && setLines(mappedLines as any);
      setTableData({
        type: "INIT_TABLE",
        column: "lineNumber",
        data: mappedLines.map((line) => {
          if (line.orderedQuantity && line.quantity > line.orderedQuantity) {
            return {
              ...line,
              quantityOrgin: line.quantity,
              orderedQuantity: line.quantity,
            };
          } else {
            return line;
          }
        }),
      });
    },
    [group, invoicesWithDebitNotes, invoicesWithProvision, isDFSCategoryOrigin]
  );

  const filterType = useCallback(
    (types: DictionaryItem[], category: string): DictionaryItem[] => {
      return types.filter((type) => {
        return (
          category &&
          // @ts-ignore
          claimTypeMap[`${category}`] &&
          // @ts-ignore
          claimTypeMap[`${category}`].includes(type.key)
        );
      });
    },
    []
  );

  const changeCategory = useCallback(
    (event: SyntheticEvent, data: any): void => {
      setCategory(data.value);
      setVin("");
      setBin("");
      setPamir("");
      setVehicleModel("");
      setCustomInvoiceNumber("");
      setRentalCar(false);
      const type = filterType(dictionaries["claim-type"], data.value);
      if (type.length === 1) {
        setType(type[0].value);
      } else {
        setType(ClaimGroup.unset);
      }
      switch (data.value) {
        case claimCategories.RK1_ZNRE:
          setActors(["WAREHOUSE", "TRANSPORT"]);
          setProcessAutomatically(true);
          break;
        case claimCategories.RK1_ZRDP:
        case claimCategories.RK1_ZRVE:
        case claimCategories.RK2_ZRNF:
          setActors(["WAREHOUSE", "TRANSPORT"]);
          setProcessAutomatically(false);
          break;
        case claimCategories.RK1_ZRPN:
          setActors([]);
          setProcessAutomatically(true);
          break;
        default:
          setActors([]);
          setProcessAutomatically(false);
          break;
      }
    },
    [dictionaries, filterType]
  );

  const changeActors = useCallback((event: SyntheticEvent, data: any): void => {
    setActors(data.value);
  }, []);

  const changeType = useCallback((event: SyntheticEvent, data: any): void => {
    setType(data.value);
  }, []);

  const changeCustomer = useCallback(
    (data: any): string => {
      setCustomer(data.value);
      setDelivery("");
      selectCustomer(data.value);
      return data.title;
    },
    [selectCustomer]
  );

  const changeDelivery = useCallback(
    (event: SyntheticEvent, data: any): void => {
      setDelivery(data.value);
    },
    []
  );

  const inputDescription = useCallback(
    (event: SyntheticEvent, data: any): void => {
      setDescription(data.value);
    },
    []
  );

  const changeVin = useCallback((event: SyntheticEvent, data: any): void => {
    if (data.value.length > 17) return;
    setVin(data.value);
  }, []);

  const changeBin = useCallback((event: SyntheticEvent, data: any): void => {
    if (data.value.length > 100) return;
    setBin(data.value);
  }, []);

  const changePamir = useCallback((event: SyntheticEvent, data: any): void => {
    if (data.value.length > 100) return;
    setPamir(data.value);
  }, []);

  const changeCustomerInvoiceNumber = useCallback(
    (event: SyntheticEvent, data: any): void => {
      if (data.value.length > 20) return;
      setCustomInvoiceNumber(data.value);
    },
    []
  );

  return {
    open,
    setOpen,
    group,
    setGroup,
    invoice,
    setInvoice,
    order,
    setOrder,
    category,
    setCategory,
    actors,
    setActors,
    type,
    setType,
    customer,
    setCustomer,
    delivery,
    setDelivery,
    description,
    setDescription,
    vin,
    setVin,
    bin,
    setBin,
    customInvoiceNumber,
    setCustomInvoiceNumber,
    pamir,
    setPamir,
    vehicleModel,
    setVehicleModel,
    rentalCar,
    setRentalCar,
    lines,
    setLines,
    openCollectiveTask,
    setOpenCollectiveTask,
    processAutomatically,
    setProcessAutomatically,
    tableData,
    setTableData,
    categoriesWithActors,
    changeGroup,
    resetAfterSearchType,
    isDFSCategoryOrigin,
    changeInvoice,
    filterType,
    changeCategory,
    changeType,
    changeActors,
    changeCustomer,
    changeDelivery,
    inputDescription,
    changeVin,
    changeBin,
    changePamir,
    changeCustomerInvoiceNumber,
  };
};
