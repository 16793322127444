import {connect} from "react-redux";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  Modal,
  Popup,
  Search,
  Table,
  TextArea,
} from "semantic-ui-react";
import {ApplicationState} from "~/store";
import {clearCreated, createClaim, fetchChatTargetGroups,} from "~/store/claims/actions";
import {fetchInvoices, fetchInvoicesWithDebitNotes, fetchInvoicesWithProvision,} from "~/store/invoices/actions";
import {IClaimCreate} from "~/store/claims/types";
import {fetchOrders} from "~/store/orders/actions";
import {selectCustomer} from "~/store/customer/actions";
import {fetchPriceList} from "~/store/price-list/actions";
import {SparePartPricing} from "~/store/price-list/types";
import {DeliveryLocation, PaginableCustomer} from "~/store/customer/types";
import {DictionaryItem, DictionaryName} from "~/store/dictionaries/types";
import {fetchDictionary} from "~/store/dictionaries/actions";
import {FormattedMessage} from "react-intl";

import React, {SyntheticEvent, useCallback, useEffect, useState} from "react";
import UberSearch from "~/components/UberSearch/UberSearch";
// @ts-ignore
import debounce from "lodash.debounce";
import translations from "~/utils/translations";
import {FormInputNumber} from "~/components/InputNumber/InputNumber";
import InfoIcon from "~/pages/Orders/Details/lib/InfoIcon";
import {toastSuccess} from "~/utils/toast";
import {useSearch} from "../useSearch";
import {TAddClaimProps, TReduxActions, TReduxState} from "./AddCLaimTypes";
import {useAddClaimState} from "./hooks/useAddClaimState";
import {useAddClaimValidation} from "./hooks/useAddClaimValidation";
import {ClaimCategory, ClaimGroup} from "../claimTypes";

const _ = require("lodash");

const AddClaim: React.FC<TAddClaimProps> = ({
  created,
  creating,
  invoicesWithProvision,
  loadingInvoicesWithDebitNotes,
  loadingInvoicesWithProvision,
  loadingCustomers,
  deliveryAddresses,
  dictionaries,
  triggerOpen,
  invoicesWithDebitNotes,
  chatTargetGroups,
  fetchInvoicesWithDebitNotes,
  fetchDictionary,
  selectCustomer,
  createClaim,
  createdSuccess,
  clearCreated,
  fetchInvoicesWithProvision,
  openHandler,
  fetchChatTargetGroups,
}) => {
  const {
    open,
    setOpen,
    group,
    setGroup,
    invoice,
    setInvoice,
    order,
    setOrder,
    category,
    setCategory,
    actors,
    type,
    setType,
    customer,
    setCustomer,
    delivery,
    setDelivery,
    description,
    setDescription,
    vin,
    setVin,
    bin,
    setBin,
    customInvoiceNumber,
    setCustomInvoiceNumber,
    pamir,
    setPamir,
    vehicleModel,
    setVehicleModel,
    rentalCar,
    setRentalCar,
    lines,
    setLines,
    openCollectiveTask,
    setOpenCollectiveTask,
    processAutomatically,
    setProcessAutomatically,
    tableData,
    setTableData,
    categoriesWithActors,
    changeGroup,
    resetAfterSearchType,
    isDFSCategoryOrigin,
    changeInvoice,
    filterType,
    changeCategory,
    changeType,
    changeActors,
    changeCustomer,
    changeDelivery,
    inputDescription,
    changeVin,
    changeBin,
    changePamir,
    changeCustomerInvoiceNumber,
  } = useAddClaimState({
    invoicesWithDebitNotes,
    invoicesWithProvision,
    selectCustomer,
    dictionaries,
  });

  const {
    isInvalidLine,
    isInvalidPNRPrice,
    renderPNRErrorMessage,
    isInvalidPVPPrice,
    validFormMessage,
    isValidForm,
    validNumber,
  } = useAddClaimValidation({
    lines,
    setLines,
    group,
    category,
    type,
    description,
    invoice,
    customer,
    vin,
    bin,
    pamir,
    order,
    vehicleModel,
    delivery,
  });

  const { column, direction, loading } = tableData;
  const timeoutRef = React.useRef();

  useEffect(() => {
    if (triggerOpen) {
      setOpen(true);
      setTableData({ type: "INIT_TABLE", column: null, data: [] });
    }
  }, [triggerOpen, setOpen, setTableData]);

  useEffect(() => {
    openHandler(open);
  }, [open, openHandler]);

  useEffect(() => {
    open &&
      fetchDictionary(
        DictionaryName.claimType,
        DictionaryName.claimCategory,
        DictionaryName.claimGroup
      );
    open && fetchChatTargetGroups();
  }, [open, fetchDictionary, fetchChatTargetGroups]);

  useEffect(() => {
    if (openCollectiveTask) {
      setOpenCollectiveTask(false);
    }
  }, [openCollectiveTask, setOpenCollectiveTask]);

  // eslint-disable-next-line
  const debouncedSearchInvoice = useCallback(
    debounce((nextValue: any) => getInvoicesWithDebitNotes(nextValue), 1000),
    []
  );

  const searchInvoice = (event: SyntheticEvent, data: any): void => {
    debouncedSearchInvoice({
      filters: [{ name: searchType, key: data.searchQuery }],
    });
  };

  // eslint-disable-next-line
  const debouncedSearchProvision = useCallback(
    debounce((nextValue: any) => getInvoicesWithProvision(nextValue), 1000),
    []
  );

  const searchInvoiceProvision = (event: SyntheticEvent, data: any): void => {
    debouncedSearchProvision({
      filters: [{ name: searchType, key: data.searchQuery }],
    });
  };

  const changeProcessAutomatically = (
    event: SyntheticEvent,
    data: any
  ): void => {
    setProcessAutomatically(data.checked);
  };

  const mapCustomers = (response: PaginableCustomer): any => {
    return response.content.map((c) => ({
      key: c.rrdi,
      title: `${c.rrdi} ${c.name}`,
      description: `${c.informationAddressCity}, ${c.informationAddressStreet} ${c.informationAddressNumber}`,
      value: c.rrdi,
    }));
  };

  const mapDelivery = (
    deliveryAddresses: DeliveryLocation[]
  ): DictionaryItem[] => {
    return deliveryAddresses.map((deliveryAddress) => ({
      key: deliveryAddress.rrdi,
      text: `${deliveryAddress.rrdi} ${deliveryAddress.company || ""}, ${deliveryAddress.city || ""}`,
      value: deliveryAddress.rrdi,
    }));
  };

  const mapProducts = (products: SparePartPricing[]): any[] => {
    return products.map((product) => ({
      product,
      key: product.referenceId,
      title: product.referenceId,
      description: product.description,
    }));
  };

  const filterCategory = (
    categories: DictionaryItem[],
    group: string
  ): DictionaryItem[] => {
    return categories.filter((category) => {
      const categoryKeyArray = category.key.split("_");
      return categoryKeyArray[0] === group;
    });
  };

  const handleConfirm = (): void => {
    let claim: IClaimCreate;
    if (group === ClaimGroup.realtedToInvoice) {
      claim = {
        processAutomatically,
        invoiceNumber: invoice,
        claimGroup: group,
        claimCategory: category,
        description: description,
        claimType: type,
        chatTargetGroups: actors,
        claimLines: lines
          .filter((line) => line.selected)
          .map((line) => ({
            lineNumber: line.lineNumber,
            claimedQuantity: line.quantity,
          })),
      };
    } else if (
      group === ClaimGroup.notRealtedToInvoice ||
      group === ClaimGroup.withoutInvoice
    ) {
      claim = {
        claimGroup: group,
        claimCategory: category,
        claimType: type.length ? type : null,
        orderCustomerID: delivery,
        accountingCustomerID: customer,
        chatTargetGroups: category === ClaimCategory.surplusWithReturn ? actors : undefined,
        description: description,
        claimLines: lines
          .filter((line) => line.selected)
          .map((line) => {
            if (isDFSCategoryOrigin(line)) {
              return {
                lineNumber: line.lineNumber,
                referenceID: line.referenceId,
                claimedQuantity: line.quantity,
                pvpPrice: line.pvpPrice,
                pnrPrice: line.pnrPrice,
                categoryOrigin: line.categoryOrigin,
              };
            } else {
              return {
                lineNumber: line.lineNumber,
                referenceID: line.referenceId,
                claimedQuantity: line.quantity,
              };
            }
          }),
      };
      if (category === ClaimCategory.batteryWithoutInvoice) {
        Object.assign(claim, {
          vin,
          bin,
          pamir,
          customInvoiceNumber,
          processAutomatically,
        });
      }
      if (category === ClaimCategory.coreWithoutInvoice) {
        Object.assign(claim, { customInvoiceNumber, processAutomatically });
      }
    } else if (group === ClaimGroup.RK3) {
      claim = {
        claimGroup: group,
        claimCategory: category,
        orderNumber: order,
        description: description,
        claimLines: lines
          .filter((line) => line.selected)
          .map((line) => ({
            lineNumber: line.lineNumber,
            claimedQuantity: line.quantity,
          })),
      };
      if (category === ClaimCategory.lackClaim) {
        Object.assign(claim, {
          vin: vin,
          vehicleModel: vehicleModel,
          rentalCar: rentalCar,
        });
      }
      if (
        category !== ClaimCategory.lackClaim &&
        category !== ClaimCategory.orderIntention
      ) {
        Object.assign(claim, { claimType: type });
      }
    } else if (group === ClaimGroup.provision) {
      claim = {
        processAutomatically,
        claimGroup: group,
        claimCategory: category,
        invoiceNumber: invoice,
        description: description,
        claimLines: lines
          .filter((line) => line.selected)
          .map((line) => ({
            lineNumber: line.lineNumber,
            referenceID: line.referenceId,
            claimedQuantity: line.quantity,
          })),
      };
      if (category === ClaimCategory.tractionBatteryReturn) {
        Object.assign(claim, { vin, bin, pamir });
      }
    } else {
      return;
    }

    createClaim(claim);
  };

  const getInvoicesWithDebitNotes = (params?: Object): void => {
    const paramsObj = {
      page: 1,
      size: 13,
      filters: [],
      sortColumn: null,
      sortDirection: null,
    };
    fetchInvoicesWithDebitNotes(Object.assign(paramsObj, params));
  };

  const getInvoicesWithProvision = (params?: Object): void => {
    const paramsObj = {
      page: 1,
      size: 13,
      filters: [],
      sortColumn: null,
      sortDirection: null,
    };
    fetchInvoicesWithProvision(Object.assign(paramsObj, params));
  };

  const clearForm = (): void => {
    setGroup(ClaimGroup.unset);
    setInvoice("");
    setOrder("");
    setCategory(ClaimCategory.unset);
    setType(ClaimGroup.unset);
    setCustomer("");
    setDelivery("");
    setDescription("");
    setVin("");
    setBin("");
    setPamir("");
    setVehicleModel("");
    setRentalCar(false);
    setLines([]);
    setCustomInvoiceNumber("");
    setProcessAutomatically(false);
  };

  const handleCancel = (): void => {
    clearForm();
    if (!creating) {
      setOpen(false);
    }
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  if (created && open) {
    clearCreated();
    clearForm();
    setOpen(false);
    createdSuccess();
  }

  const changeQuantity = (
    lineNumber: number,
    event: SyntheticEvent,
    data: any
  ): void => {
    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.lineNumber === lineNumber) {
          line.quantity = parseInt(data.value);
          line.selected = true;
          tableData.data = prevLines;
        }
        return { ...line };
      })
    );
  };

  const changePVPPrice = (
    lineNumber: number,
    event: SyntheticEvent,
    data: any
  ): void => {
    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.lineNumber === lineNumber) {
          line.pvpPrice = parseFloat(data.value);
          line.selected = true;
          tableData.data = prevLines;
        }
        return { ...line };
      })
    );
  };

  const changePNRPrice = (
    lineNumber: number,
    event: SyntheticEvent,
    data: any
  ): void => {
    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.lineNumber === lineNumber) {
          line.pnrPrice = parseFloat(data.value);
          line.selected = true;
          tableData.data = prevLines;
        }
        return { ...line };
      })
    );
  };

  const changeReferenceId = (lineNumber: number, data: any): string => {
    setLines(
      lines.map((line) => {
        if (line.lineNumber === lineNumber) {
          if (
            group === ClaimGroup.notRealtedToInvoice ||
            ClaimGroup.withoutInvoice
          ) {
            return {
              ...line,
              referenceId: data.key,
              selected: true,
              pvpPrice: data.product.pvpPrice,
              pnrPrice: undefined,
              categoryOrigin: data.product.categoryOrigin,
            };
          } else {
            return { ...line, referenceId: data.key, selected: true };
          }
        }
        return { ...line };
      })
    );
    return `${data.title} ${data.description}`;
  };

  const selectAll = (event: SyntheticEvent, data: any): void => {
    setLines(
      lines.map((line) => ({
        ...line,
        selected: data.checked,
      }))
    );
    setTableData({
      type: "SELECT_ALL",
      column: "lineNumber",
      data: lines,
      selected: data.checked,
    });
  };

  const isCheckedAll = (tableData: any): boolean => {
    return !(
      tableData.data === null ||
      tableData.data.length === 0 ||
      tableData.data.some((line: { selected: boolean }) => !line.selected)
    );
  };

  const selectLine = (
    lineNumber: number,
    event: SyntheticEvent,
    data: any
  ): void => {
    setLines((prevLines) =>
      prevLines.map((line) => {
        if (line.lineNumber === lineNumber) {
          setTableData({
            type: "SELECT",
            column: "lineNumber",
            data: prevLines,
            checkedLine: lineNumber,
            selected: data.checked,
          });
          return { ...line, selected: data.checked };
        }
        return { ...line };
      })
    );
  };

  const addLine = (): void => {
    const newLines = [...lines];
    newLines.push({
      lineNumber:
        lines.length === 0 ? 1 : lines[lines.length - 1].lineNumber + 1,
      referenceId: "",
      quantity: 1,
      quantityOrgin: 1,
      selected: true,
      packingForceQuantity: null,
      pvpPrice: 0,
      pnrPrice: 0,
      categoryOrigin: "0",
    });
    setLines(newLines);
  };

  const removeLine = (lineNumber: number): void => {
    setLines(
      lines
        .filter((line) => line.lineNumber !== lineNumber)
        .map((line, index) => ({ ...line, lineNumber: index + 1 }))
    );
  };

  const getTableData = (group: string): any => {
    if (
      group === ClaimGroup.notRealtedToInvoice ||
      group === ClaimGroup.withoutInvoice
    ) {
      return lines;
    } else {
      return tableData.data;
    }
  };

  const errorMessage = validFormMessage();

  const [searchValue, setSearchValue] = useState("");
  const [searchPackageNr, setSearchPackageNr] = useState("");

  const handleInputChange = useCallback(
    (e, data, searchType: "search" | "searchPackageNr") => {
      if (searchType === "search") {
        setSearchValue(data.value);
      } else if (searchType === "searchPackageNr") {
        setSearchPackageNr(data.value);
      }
    },
    []
  );

  const handleSearchChange = useCallback(() => {
    setTableData({ type: "START_SEARCH", query: "query" });
    timeoutRef.current = setTimeout(() => {
      if (searchValue.length === 0 && searchPackageNr.length === 0) {
        setTableData({ type: "INIT_TABLE", data: lines });
        return;
      }
      const re = new RegExp(_.escapeRegExp(searchValue), "i");
      const rePackageNr = new RegExp(_.escapeRegExp(searchPackageNr), "i");
      const isMatch = (result: any) => {
        return (
          re.test(result.referenceId + result.orderNumber) &&
          rePackageNr.test(result.packageNumber)
        );
      };
      setTableData({
        type: "FINISH_SEARCH",
        results: _.filter(lines, isMatch),
        data: _.filter(lines, isMatch),
      });
    }, 300) as any;
  }, [lines, searchValue, searchPackageNr, setTableData]);

  useEffect(() => {
    handleSearchChange();
  }, [searchValue, searchPackageNr, handleSearchChange]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const copyToClipboard = (text: string): void => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.top = "-50px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      toastSuccess("Numer paczki został skopiowany", text);
    } catch (err) {
      console.error("Nie udało się skopiować numeru paczki");
    }
    document.body.removeChild(textArea);
  };

  const mapChatTargetGroups = () => {
    if (!chatTargetGroups) return [];
    return chatTargetGroups.map((item) => ({
      text: translations.format("app." + item),
      value: item,
    }));
  };

  const { SearchFormField, searchType } = useSearch({
    group,
    invoice,
    loadingInvoicesWithDebitNotes,
    loadingInvoicesWithProvision,
    invoicesWithDebitNotes,
    invoicesWithProvision,
    changeInvoice,
    searchInvoice,
    searchInvoiceProvision,
    resetAfterSearchType,
  });

  return (
    <Modal
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <Header
        icon="exclamation triangle"
        content="Dodaj reklamacje lub zwrot"
      />
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Rodzaj składanej reklamacji lub zwrotu*</label>
            <Dropdown
              placeholder="Wybierz rodzaj"
              fluid
              selection
              value={group}
              onChange={changeGroup}
              options={dictionaries["claim-group"].filter(
                (group) => group.key !== ClaimGroup.RK3
              )}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field disabled={group === ClaimGroup.notRealtedToInvoice}>
            <Checkbox
              label="Procesuj automatyczne"
              checked={processAutomatically}
              onChange={changeProcessAutomatically}
            />
          </Form.Field>
          {SearchFormField}
          <Form.Group widths="equal">
            <Form.Field
              disabled={
                group === ClaimGroup.realtedToInvoice ||
                group === ClaimGroup.provision
              }
            >
              <label>Kod RRDI płatnika za zamówienie*</label>
              <UberSearch
                fluid
                placeholder="Wybierz płatnika"
                endpoint="/customers"
                mapper={mapCustomers}
                queryParams={[
                  {
                    name: "onlyPayers",
                    value: "true",
                  },
                ]}
                onResultSelect={changeCustomer}
              />
            </Form.Field>
            <Form.Field
              disabled={
                group === ClaimGroup.realtedToInvoice ||
                group === ClaimGroup.provision ||
                customer === ""
              }
            >
              <label>Kod RRDI klienta do którego nastąpiła dostawa*</label>
              <Dropdown
                placeholder="Wybierz klienta"
                fluid
                selection
                value={delivery}
                style={{ display: "inline-grid" }}
                onChange={changeDelivery}
                loading={loadingCustomers}
                options={mapDelivery(deliveryAddresses)}
                noResultsMessage={
                  <FormattedMessage id="app.noResultsMessage" />
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Field disabled={group === ""}>
            <label>Kategoria składanej reklamacji lub zwrotu*</label>
            <Dropdown
              placeholder="Wybierz kategorię"
              fluid
              selection
              value={category}
              onChange={changeCategory}
              options={filterCategory(dictionaries["claim-category"], group)}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field
            disabled={
              !chatTargetGroups ||
              !chatTargetGroups.length ||
              !category ||
              !categoriesWithActors.find((item) => item === category)
            }
          >
            <label>Wybierz aktorów dla reklamacji</label>
            <Dropdown
              placeholder="Wybierz aktorów"
              multiple
              fluid
              selection
              value={actors}
              onChange={changeActors}
              options={mapChatTargetGroups()}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field
            disabled={
              category === "" ||
              category === ClaimCategory.lackClaim ||
              category === ClaimCategory.orderIntention ||
              group === ClaimGroup.provision ||
              group === ClaimGroup.withoutInvoice
            }
          >
            <label>Typ składanej reklamacji lub zwrotu*</label>
            <Dropdown
              placeholder="Wybierz typ"
              fluid
              selection
              value={type}
              onChange={changeType}
              options={filterType(dictionaries["claim-type"], category)}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field>
            <label>Opis reklamacji na podstawie informacji od klienta*</label>
            <TextArea
              placeholder="Wypełnij opis"
              value={description}
              onInput={inputDescription}
            />
          </Form.Field>
          {group === ClaimGroup.withoutInvoice ? (
            <Form.Group widths={3}>
              <Form.Field>
                <label>Nr faktury</label>
                <Input
                  placeholder="Wypełnij Nr faktury"
                  value={customInvoiceNumber}
                  onChange={changeCustomerInvoiceNumber}
                />
              </Form.Field>
            </Form.Group>
          ) : null}
          {category === ClaimCategory.tractionBatteryReturn ||
          category === ClaimCategory.batteryWithoutInvoice ? (
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Numer VIN*</label>
                <Input
                  placeholder="Wypełnij VIN"
                  value={vin}
                  onChange={changeVin}
                />
              </Form.Field>
              <Form.Field required>
                <label>Numer BIN*</label>
                <Input
                  placeholder="Wypełnij BIN"
                  value={bin}
                  onChange={changeBin}
                />
              </Form.Field>
              <Form.Field required>
                <label>Numer PAMIR*</label>
                <Input
                  placeholder="Wypełnij PAMIR"
                  value={pamir}
                  onChange={changePamir}
                />
              </Form.Field>
            </Form.Group>
          ) : null}
          <div style={{ display: "flex", gap: 20 }}>
            <div>
              <Form.Field>
                <label>Lista linii do reklamacji</label>
              </Form.Field>
              {group !== ClaimGroup.notRealtedToInvoice &&
              group !== ClaimGroup.withoutInvoice ? (
                <Search
                  loading={loading}
                  open={false}
                  onSearchChange={(e, d) => handleInputChange(e, d, "search")}
                  placeholder={"Referencja | Nr zamówienia"}
                />
              ) : null}
            </div>
            <div>
              <Form.Field>
                <label style={{ visibility: "hidden" }}>Numer paczki</label>
              </Form.Field>
              {group !== ClaimGroup.notRealtedToInvoice &&
              group !== ClaimGroup.withoutInvoice ? (
                <Search
                  loading={loading}
                  open={false}
                  onSearchChange={(e, d) =>
                    handleInputChange(e, d, "searchPackageNr")
                  }
                  placeholder={"Numer paczki"}
                />
              ) : null}
            </div>
          </div>
          <Table
            sortable={
              group !== ClaimGroup.notRealtedToInvoice &&
              group !== ClaimGroup.withoutInvoice
            }
            celled
            basic
          >
            <Table.Header key={"line"}>
              <Table.Row>
                <Table.HeaderCell width={1}>
                  {group !== ClaimGroup.provision &&
                  group !== ClaimGroup.notRealtedToInvoice &&
                  group !== ClaimGroup.withoutInvoice ? (
                    <Checkbox
                      checked={isCheckedAll(tableData)}
                      disabled={lines.length === 0}
                      onChange={selectAll}
                    />
                  ) : null}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "lineNumber" ? direction : null}
                  onClick={() =>
                    setTableData({ type: "CHANGE_SORT", column: "lineNumber" })
                  }
                >
                  {translations.format("app.claim.line.number")}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={column === "referenceId" ? direction : null}
                  onClick={() =>
                    setTableData({ type: "CHANGE_SORT", column: "referenceId" })
                  }
                >
                  {translations.format("app.reference")}
                </Table.HeaderCell>
                {group !== ClaimGroup.notRealtedToInvoice &&
                group !== ClaimGroup.withoutInvoice ? (
                  <Table.HeaderCell
                    sorted={
                      column ===
                      (group === ClaimGroup.realtedToInvoice
                        ? "orderedQuantity"
                        : "quantityOrgin")
                        ? direction
                        : null
                    }
                    onClick={() =>
                      setTableData({
                        type: "CHANGE_SORT",
                        column:
                          group === ClaimGroup.realtedToInvoice
                            ? "orderedQuantity"
                            : "quantityOrgin",
                      })
                    }
                  >
                    {translations.format("app.claim.ordered-quantity")}
                  </Table.HeaderCell>
                ) : null}
                {group === ClaimGroup.notRealtedToInvoice ||
                group === ClaimGroup.withoutInvoice ? (
                  <>
                    <Table.HeaderCell
                      width={1}
                      textAlign={"center"}
                      sorted={column === "categoryOrigin" ? direction : null}
                      onClick={() =>
                        setTableData({
                          type: "CHANGE_SORT",
                          column: "categoryOrigin",
                        })
                      }
                    >
                      {translations.format("DFS")}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      width={2}
                      sorted={column === "pvpPrice" ? direction : null}
                      onClick={() =>
                        setTableData({
                          type: "CHANGE_SORT",
                          column: "pvpPrice",
                        })
                      }
                    >
                      {translations.format("app.claim.pvpPrice")}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      width={2}
                      sorted={column === "pnrPrice" ? direction : null}
                      onClick={() =>
                        setTableData({
                          type: "CHANGE_SORT",
                          column: "pnrPrice",
                        })
                      }
                    >
                      {translations.format("app.claim.pnrPrice")}
                    </Table.HeaderCell>
                  </>
                ) : null}
                <Table.HeaderCell
                  sorted={column === "quantity" ? direction : null}
                  onClick={() =>
                    setTableData({ type: "CHANGE_SORT", column: "quantity" })
                  }
                >
                  {translations.format("app.claim.quantity")}
                </Table.HeaderCell>
                {group === ClaimGroup.realtedToInvoice && (
                  <Table.HeaderCell
                    sorted={column === "orderNumber" ? direction : null}
                    onClick={() =>
                      setTableData({
                        type: "CHANGE_SORT",
                        column: "orderNumber",
                      })
                    }
                  >
                    {translations.format("app.claim.order.number")}
                  </Table.HeaderCell>
                )}
                {group === ClaimGroup.provision && (
                  <Table.HeaderCell
                    sorted={column === "orderNumber" ? direction : null}
                    onClick={() =>
                      setTableData({
                        type: "CHANGE_SORT",
                        column: "orderNumber",
                      })
                    }
                  >
                    {translations.format("app.claim.order.number")}
                  </Table.HeaderCell>
                )}
                {group === ClaimGroup.notRealtedToInvoice ||
                group === ClaimGroup.withoutInvoice ? (
                  <Table.HeaderCell />
                ) : null}
                {group === ClaimGroup.realtedToInvoice && (
                  <Table.HeaderCell
                    sorted={column === "packageNumber" ? direction : null}
                    onClick={() =>
                      setTableData({
                        type: "CHANGE_SORT",
                        column: "packageNumber",
                      })
                    }
                  >
                    {translations.format("app.claim.order.packageNumber")}
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getTableData(group).map((line: any) => (
                <Table.Row key={line.lineNumber}>
                  <Table.Cell>
                    <Checkbox
                      checked={line.selected}
                      onChange={(e, d) => selectLine(line.lineNumber, e, d)}
                    />
                  </Table.Cell>
                  <Table.Cell>{line.lineNumber}</Table.Cell>
                  <Table.Cell>
                    {group === ClaimGroup.notRealtedToInvoice ||
                    group === ClaimGroup.withoutInvoice ? (
                      <UberSearch
                        fluid
                        value={line.referenceId}
                        input={{ placeholder: "Wybierz produkt" }}
                        endpoint="/spare-parts-price-list"
                        queryParams={[
                          { name: "isClaim", value: "true" },
                        ]}
                        mapper={(response) =>
                          mapProducts(response.slice(0, 10))
                        }
                        onResultSelect={(product: any) =>
                          changeReferenceId(line.lineNumber, product)
                        }
                      />
                    ) : (
                      line.referenceId
                    )}
                  </Table.Cell>
                  {group !== ClaimGroup.notRealtedToInvoice &&
                  group !== ClaimGroup.withoutInvoice ? (
                    <Table.Cell>
                      {line.quantityOrgin
                        ? line.quantityOrgin
                        : line.orderedQuantity}
                    </Table.Cell>
                  ) : null}
                  {group === ClaimGroup.notRealtedToInvoice ||
                  group === ClaimGroup.withoutInvoice ? (
                    <>
                      <Table.Cell textAlign={"center"}>
                        {isDFSCategoryOrigin(line) ? (
                          <Icon name="check" color="grey" fitted />
                        ) : (
                          <Icon name="x" color="grey" fitted />
                        )}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        {isDFSCategoryOrigin(line) ? (
                          <Popup
                            content={
                              !isInvalidPVPPrice(line)
                                ? `${translations.format("app.claim.pvpPrice")}: ${line.pvpPrice}`
                                : `Wartość powinna być większa od 0`
                            }
                            trigger={
                              <FormInputNumber
                                min={0}
                                precision={2}
                                placeholder="..."
                                fluid
                                value={line.pvpPrice}
                                error={isInvalidPVPPrice(line)}
                                style={{ width: 100, opacity: 1 }}
                                onChange={(e, d) =>
                                  changePVPPrice(line.lineNumber, e, d)
                                }
                              />
                            }
                          ></Popup>
                        ) : null}
                      </Table.Cell>
                    </>
                  ) : null}
                  {group === ClaimGroup.notRealtedToInvoice ||
                  group === ClaimGroup.withoutInvoice ? (
                    <Table.Cell collapsing>
                      {isDFSCategoryOrigin(line) ? (
                        <Popup
                          content={
                            !isInvalidPNRPrice(line)
                              ? `${translations.format("app.claim.pnrPrice")}: ${line.pnrPrice}`
                              : renderPNRErrorMessage(line)
                          }
                          trigger={
                            <FormInputNumber
                              min={0}
                              precision={2}
                              placeholder="..."
                              fluid
                              value={line.pnrPrice}
                              error={isInvalidPNRPrice(line)}
                              style={{ width: 100, opacity: 1 }}
                              onChange={(e, d) =>
                                changePNRPrice(line.lineNumber, e, d)
                              }
                            />
                          }
                        ></Popup>
                      ) : null}
                    </Table.Cell>
                  ) : null}
                  <Table.Cell collapsing>
                    <Popup
                      content={
                        !isInvalidLine(line)
                          ? `${translations.format("app.claim.quantity")}: ${line.quantity}`
                          : "Wartość powinna być większa od zera"
                      }
                      trigger={
                        <FormInputNumber
                          min={0}
                          placeholder="..."
                          fluid
                          value={
                            validNumber(line.quantity) ? line.quantity : ""
                          }
                          error={isInvalidLine(line)}
                          style={{ width: 100, opacity: 1 }}
                          max={group === ClaimGroup.provision ? 1 : undefined}
                          onChange={(e, d) =>
                            changeQuantity(line.lineNumber, e, d)
                          }
                          disabled={group === ClaimGroup.withoutInvoice}
                        />
                      }
                    ></Popup>
                  </Table.Cell>
                  {group === ClaimGroup.realtedToInvoice && (
                    <Table.Cell>{line.orderNumber}</Table.Cell>
                  )}
                  {group === ClaimGroup.provision && (
                    <Table.Cell>{line.orderNumber}</Table.Cell>
                  )}
                  {group === ClaimGroup.notRealtedToInvoice ||
                  group === ClaimGroup.withoutInvoice ? (
                    <Table.HeaderCell>
                      <Popup
                        trigger={
                          <Button
                            circular
                            basic
                            icon="remove"
                            onClick={() => removeLine(line.lineNumber)}
                          />
                        }
                        content="Usuń linię z reklamacji"
                      />
                    </Table.HeaderCell>
                  ) : null}
                  {group === ClaimGroup.realtedToInvoice ? (
                    <Table.Cell textAlign="center">
                      {!line.packageNumber && "-"}
                      {line.packageNumber && (
                        <InfoIcon
                          documents={line.packageNumber || []}
                          position="top right"
                          clickableItem={(document) => (
                            <Button
                              style={{ display: "block", width: "100%" }}
                              compact
                              basic
                              onClick={() => {
                                copyToClipboard(document);
                              }}
                            >
                              {document}
                            </Button>
                          )}
                          noResultsMessage="Brak numeru paczki"
                        />
                      )}
                    </Table.Cell>
                  ) : null}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {group === ClaimGroup.notRealtedToInvoice ||
          (group === ClaimGroup.withoutInvoice && lines.length < 1) ? (
            <Popup
              trigger={<Button circular basic icon="add" onClick={addLine} />}
              content="Dodaj kolejną linię do reklamacji"
            />
          ) : null}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Popup
          trigger={
            <span>
              <Button
                content="Zatwierdź"
                icon="add"
                labelPosition="left"
                primary
                loading={creating}
                onClick={handleConfirm}
                disabled={!isValidForm() || creating}
              />{" "}
            </span>
          }
          content={errorMessage}
          disabled={isValidForm()}
        />
        <Button content="Anuluj" onClick={handleCancel} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  claims,
  invoices,
  customers,
  dictionaries,
}: ApplicationState) => ({
  chatTargetGroups: claims.chatTargetGroups,
  dictionaries,
  created: claims.created,
  creating: claims.creating,
  deliveryAddresses: customers.selected
    ? customers.selected.deliveryAddresses
    : [],
  invoicesWithDebitNotes: invoices.invoicesWithDebitNotes.list,
  invoicesWithProvision: invoices.invoicesWithProvision.list,
  loadingCustomers: customers.loading || dictionaries.loading,
  loadingInvoicesWithDebitNotes: invoices.loadingInvoicesWithDebitNotes,
  loadingInvoicesWithProvision:
    invoices.invoicesWithProvision.loading || dictionaries.loading,
});

const mapDispatchToProps: TReduxActions = {
  createClaim,
  clearCreated,
  fetchInvoices,
  fetchInvoicesWithProvision,
  fetchOrders,
  selectCustomer,
  fetchPriceList,
  fetchDictionary,
  fetchInvoicesWithDebitNotes,
  fetchChatTargetGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClaim);
