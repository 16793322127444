import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Dimmer, Divider, Grid } from "semantic-ui-react";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import { parsePrice } from "~/utils/parsePrice";
import { acceptedValue, claimedValue } from "./lib/AcceptValues";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { ApplicationState } from "~/store";
import {
  getAttachment,
  selectClaim,
  getClaimLabel,
  refreshClaim,
  sendInnerMessage,
} from "~/store/claims/actions";
import { getInvoicePdf } from "~/store/invoices/actions";
import { IClaim } from "~/store/claims/types";
import { DictionaryLabel } from "~/components/MapDictionary/DictionaryLabel";
import { parseDate } from "~/utils/dateUtils";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import DetailCard from "~/components/DetailCard/DetailCard";
import CommonLoader from "~/components/Loaders/CommonLoader";
import ClaimLines from "./lib/Lines";
import AddAttachment from "./lib/Attachments/Add";
import ClaimAttachment from "./lib/Attachments/Single";
import ClaimTransition from "./lib/Transition";
import PageHeader from "~/components/PageHeader/PageHeader";
import translations from "~/utils/translations";
import ClaimHistory from "./lib/History";
import "./claim-details.scss";
import { decodeUrlClaimNumber } from "~/utils/claimNumberUrlFormatter";
import { handleError } from "~/store/error/actions";
import { allowedClaimCategories } from "../lib/claimCategoryDefinitions.conf";
import { CommentsCard } from "./lib/Cards/Comments/Comments";
import { useClaimsDetailsButtons } from "./lib/hooks/useClaimsDetailsButtons";
import { NavLink, Redirect } from "react-router-dom";
import { getEndecodedClaimNumber } from "~/store/claims/sagas";
import { CommentsModal } from "./lib/CommentsModal/CommentsModal";
import { AttachmentsModal } from "./lib/Attachments/AttachmentsModal";
import { CaseNumbers } from "./lib/Cards/CaseNumbers/CaseNumbers";
import ClaimDetailsHeader from "./lib/ClaimDetailsHeader/ClaimDetailsHeader";
import { DocumentCardContent } from "./lib/Cards/DocumentsCardContent/DocumentsCardContent";
import ClaimCloning from "./lib/ClaimCloning/ClaimCloning";
import AddInvoiceCorrection from "./lib/AddCorrection/AddInvoiceCorrection";
import { ClaimCategory } from "~/pages/Claims/lib/claimTypes";

type TReduxState = {
  claimIn?: IClaim;
  loading: boolean;
  dictionaries: DictionariesState;
};

type TReduxActions = {
  selectClaim: typeof selectClaim;
  getAttachment: typeof getAttachment;
  fetchDictionary: typeof fetchDictionary;
  getInvoicePdf: typeof getInvoicePdf;
  getClaimLabel: typeof getClaimLabel;
  refreshClaim: typeof refreshClaim;
  sendInnerMessage: typeof sendInnerMessage;
  handleError: typeof handleError;
};

type TRouteParams = RouteComponentProps<{
  claimNumber: string;
}>;

type TClaimDetailsProps = TReduxState & TReduxActions & TRouteParams;

function getCommentsOrAttachmentsDetailCard(claim: IClaim) {
  return allowedClaimCategories.COMMENTS.includes(claim.claimCategory) ? (
    <DetailCard
      title={"Czat"}
      titleFormat={true}
      id="comments"
      key="comments"
      minHeight={100}
      width={5}
    >
      <CommentsCard
        innerMessages={claim.innerMessages}
        clientMessages={claim.clientMessages}
        messagesAvailable={claim.messagesAvailable}
      />
    </DetailCard>
  ) : (
    <DetailCard
      title={translations.format(`app.attachments`, {
        "0": claim.attachments.length,
      })}
      titleFormat={true}
      id="attachments"
      key="attachments"
      minHeight={100}
      width={5}
    >
      <div className="claim-attachment">
        {claim.attachments.map((attachment, index) => (
          <ClaimAttachment
            key={index}
            claimNumber={claim.claimNumber}
            attachment={attachment}
          />
        ))}
      </div>
    </DetailCard>
  );
}

const ClaimDetails: React.FC<TClaimDetailsProps> = ({
  match,
  claimIn,
  loading,
  selectClaim,
  dictionaries,
  fetchDictionary,
  getInvoicePdf,
  getClaimLabel,
  refreshClaim,
  sendInnerMessage,
  handleError,
}) => {
  const [claim, setClaim] = useState<IClaim>();
  const [openAddAttachment, setOpenAddAttachment] = useState<boolean>(false);
  const [openAttachments, setOpenAttachments] = useState<boolean>(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const [openTransition, setOpenTransition] = useState<boolean>(false);
  const [commentsModalOpen, setCommentsModalOpen] = useState<boolean>(false);
  const [addCorrectionModalOpen, setAddCorrectionModalOpen] =
    useState<boolean>(false);

  const decodedUrlClaimNumber = decodeUrlClaimNumber(match.params.claimNumber);

  useEffect(() => {
    if (!claim || claim.claimNumber !== match.params.claimNumber) {
      fetchDictionary(
        DictionaryName.claimType,
        DictionaryName.claimCategory,
        DictionaryName.claimGroup,
        DictionaryName.claimStatus,
        DictionaryName.claimLineStatus,
        DictionaryName.claimTransportStatus,
        DictionaryName.claimWarehouseStatus
      );
      selectClaim(decodeUrlClaimNumber(match.params.claimNumber));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    decodedUrlClaimNumber,
    fetchDictionary,
    match.params.claimNumber,
    selectClaim,
  ]);

  useEffect(() => {
    document.title = translations.format("LDC_CLAIMS");
    setClaim(claimIn);
    setClonedClaim(undefined);
  }, [claimIn]);

  useEffect(() => {
    if (openAddAttachment) {
      setOpenAddAttachment(false);
    }
  }, [openAddAttachment]);

  useEffect(() => {
    if (openHistory) {
      setOpenHistory(false);
    }
  }, [openHistory]);

  useEffect(() => {
    if (openTransition) {
      setOpenTransition(false);
    }
  }, [openTransition]);

  const { renderLabel } = useRenderingFunctions(claim, true);
  const [claimCloning, setClaimCloning] = useState(false);
  const [clonedClaim, setClonedClaim] = useState<string>();

  const cloneClaimRedirect = useCallback((claimNr: string) => {
    setClonedClaim(claimNr);
  }, []);

  const buttons = useClaimsDetailsButtons({
    claim,
    setOpenAddAttachment,
    setOpenHistory,
    setOpenTransition,
    setCommentsModalOpen,
    setOpenAttachments,
    setClaimCloning,
    setAddCorrectionModalOpen,
  });

  const cards: JSX.Element[] = useMemo(() => {
    return !claim
      ? []
      : [
          <DetailCard
            title="app.informations"
            id="info"
            key="info"
            minHeight={100}
            width={6}
          >
            {claim && claim.origin ? (
              <div className="fields smart-label">
                <div className="six wide field">
                  <label>Źródło klonowania</label>
                </div>
                <div className="ten wide field" style={{ textAlign: "left" }}>
                  <span>
                    {
                      <Button
                        as={NavLink}
                        to={`/claim/${getEndecodedClaimNumber(claim.origin)}`}
                        style={{ display: "block" }}
                        compact
                        basic
                      >
                        {claim.origin}
                      </Button>
                    }
                  </span>
                </div>
              </div>
            ) : null}
            {renderLabel("Numer reklamacji", claim.claimNumber)}
            {renderLabel("Data utworzenia", parseDate(claim.createDate))}
            {renderLabel("ID klienta", claim.orderCustomerID)}
            {renderLabel("Kod płatnika", claim.accountingCustomerID)}
            {claim.supplierSystem &&
            claim.supplierSystem.findIndex(
              (sys) => sys.businessSector === "FLHAP"
            ) >= 0 ? (
              <Divider />
            ) : (
              ""
            )}
            {claim.supplierSystem &&
              renderLabel(
                translations.format("app.customer.FLHAP-Payer"),
                claim.supplierSystem[
                  claim.supplierSystem?.findIndex(
                    (sys) => sys.businessSector === "FLHAP"
                  )
                ].clientId
              )}
            {claim.supplierSystem &&
              renderLabel(
                translations.format("app.customer.FLHAP-Recipient"),
                claim.supplierSystem[
                  claim.supplierSystem?.findIndex(
                    (sys) => sys.businessSector === "FLHAP"
                  )
                ].deliveryId
              )}
            {claim.supplierSystem &&
            claim.supplierSystem.findIndex(
              (sys) => sys.businessSector === "FLHAP"
            ) >= 0 ? (
              <Divider />
            ) : (
              ""
            )}
            {claim.supplierSystem &&
              renderLabel(
                translations.format("app.customer.CJD-Payer"),
                claim.supplierSystem[
                  claim.supplierSystem?.findIndex(
                    (sys) => sys.businessSector === "CJD"
                  )
                ].clientId
              )}
            {claim.supplierSystem &&
              renderLabel(
                translations.format("app.customer.CJD-Recipient"),
                claim.supplierSystem[
                  claim.supplierSystem?.findIndex(
                    (sys) => sys.businessSector === "CJD"
                  )
                ].deliveryId
              )}
            {claim.supplierSystem &&
            claim.supplierSystem.findIndex(
              (sys) => sys.businessSector === "CJD"
            ) >= 0 ? (
              <Divider />
            ) : (
              ""
            )}
            {renderLabel(
              "Grupa",
              <DictionaryLabel
                flexDisplay
                value={claim.claimGroup}
                dictionary={dictionaries[DictionaryName.claimGroup]}
              />
            )}
            {renderLabel(
              "Kategoria",
              <DictionaryLabel
                flexDisplay
                value={claim.claimCategory}
                dictionary={dictionaries[DictionaryName.claimCategory]}
              />
            )}
            {renderLabel(
              "Typ",
              <DictionaryLabel
                flexDisplay
                value={claim.claimType}
                dictionary={dictionaries[DictionaryName.claimType]}
              />
            )}
            {renderLabel("Opis", claim.description)}
          </DetailCard>,
          <DetailCard
            title="app.documents"
            id="order"
            key="order"
            minHeight={100}
            width={5}
          >
            <DocumentCardContent
              claim={claim}
              getClaimLabel={getClaimLabel}
              getInvoicePdf={getInvoicePdf}
              renderLabel={renderLabel}
            />
          </DetailCard>,
          getCommentsOrAttachmentsDetailCard(claim),
          <DetailCard
            title="app.status"
            id="status"
            key="status"
            minHeight={100}
            width={6}
          >
            {renderLabel(
              "Status",
              <DictionaryLabel
                flexDisplay={true}
                hideKey={true}
                value={claim.status}
                dictionary={dictionaries[DictionaryName.claimStatus]}
              />
            )}
            {renderLabel(
              "Data aktualizacji statusu",
              parseDate(claim.statusDate)
            )}
            {renderLabel(
              "Procesowano automatycznie",
              <div>{claim.processAutomatically ? "Tak" : "Nie"}</div>
            )}
            {renderLabel(
              "Koszty reklamacji ponosi",
              <div style={{ display: "flex", gap: 3 }}>
                {claim.costCoveredBy
                  ? claim.costCoveredBy.map((item) => (
                      <span
                        key={item}
                        style={{
                          padding: "5px 8px",
                          backgroundColor: "#0000001a",
                          borderRadius: 3,
                        }}
                      >
                        {translations.format(`app.${item}`)}
                      </span>
                    ))
                  : "n/d"}
              </div>
            )}
            {renderLabel("Komentarz", claim.statusNotes)}
            {claim.chatTargetGroups &&
              claim.chatTargetGroups.includes("WAREHOUSE") &&
              claim.claimCategory !== ClaimCategory.surplusWithReturn && (
                <>
                  <Divider></Divider>
                  {renderLabel(
                    "Status magazynu",
                    <DictionaryLabel
                      flexDisplay={true}
                      hideKey={true}
                      value={claim.warehouseStatus || ""}
                      dictionary={
                        dictionaries[DictionaryName.claimWarehouseStatus]
                      }
                    />
                  )}
                  {renderLabel(
                    "Data aktualizacji statusu magazynu",
                    claim.warehouseStatusUpdatedAt
                      ? parseDate(claim.warehouseStatusUpdatedAt)
                      : "n/d"
                  )}
                  {renderLabel(
                    "Status zmieniony przez",
                    claim.warehouseStatusUpdatedBy
                      ? claim.warehouseStatusUpdatedBy
                      : "n/d"
                  )}
                  {renderLabel(
                    "Komentarz magazynu",
                    claim.warehouseStatusNotes ? claim.warehouseStatusNotes : ""
                  )}
                </>
              )}
            {claim.chatTargetGroups &&
              claim.chatTargetGroups.includes("TRANSPORT") &&
              claim.claimCategory !== ClaimCategory.surplusWithReturn && (
                <>
                  <Divider></Divider>
                  {renderLabel(
                    "Status transportu",
                    <DictionaryLabel
                      flexDisplay={true}
                      hideKey={true}
                      value={claim.transportStatus || ""}
                      dictionary={
                        dictionaries[DictionaryName.claimTransportStatus]
                      }
                    />
                  )}
                  {renderLabel(
                    "Data aktualizacji statusu transportu",
                    claim.transportStatusUpdatedAt
                      ? parseDate(claim.transportStatusUpdatedAt)
                      : "n/d"
                  )}
                  {renderLabel(
                    "Status zmieniony przez",
                    claim.transportStatusUpdatedBy
                      ? claim.transportStatusUpdatedBy
                      : "n/d"
                  )}
                  {renderLabel(
                    "Komentarz transportu",
                    claim.transportStatusNotes ? claim.transportStatusNotes : ""
                  )}
                </>
              )}
          </DetailCard>,
          <DetailCard
            title="Numery spraw"
            titleFormat={true}
            id="case-numbers"
            key="case-numbers"
            width={5}
          >
            <CaseNumbers claim={claim} setClaim={setClaim} />
          </DetailCard>,
          <DetailCard
            title="app.summary"
            id="summary"
            key="summary"
            minHeight={100}
            width={5}
          >
            {renderLabel(
              "Wartość reklamacji",
              <b>{parsePrice(claimedValue(claim))}</b>
            )}
            {renderLabel(
              "Wartość zaakceptowana",
              <b>{parsePrice(acceptedValue(claim))}</b>
            )}
            {(claim.vin || claim.bin || claim.pamir) && (
              <>
                <Divider></Divider>
                {renderLabel("Numer VIN", <b>{claim.vin}</b>)}
                {renderLabel("Numer BIN", <b>{claim.bin}</b>)}
                {renderLabel("Numer PAMIR", <b>{claim.pamir}</b>)}
              </>
            )}
          </DetailCard>,
          <DetailCard
            title="app.claimLines"
            id="claimLines"
            key="claimLines"
            width={16}
            minHeight={100}
          >
            <div style={{ overflowX: "scroll" }}>
              <ClaimLines
                lines={claim.claimLines}
                category={claim.claimCategory}
                statusDictionary={dictionaries[DictionaryName.claimLineStatus]}
              />
            </div>
          </DetailCard>,
        ];
  }, [claim, dictionaries, getInvoicePdf, renderLabel, getClaimLabel]);

  const handleCreateTransition = (): any =>
    selectClaim(decodeUrlClaimNumber(match.params.claimNumber));

  const cardsRendered: JSX.Element = useMemo(() => {
    return (
      <div className="uber-content">
        <Grid stretched className="claim-details">
          {cards}
        </Grid>
      </div>
    );
  }, [cards]);

  const handleAddAttachment = (): any =>
    selectClaim(decodeUrlClaimNumber(match.params.claimNumber));

  const handleRefresh = useCallback(() => {
    selectClaim(decodeUrlClaimNumber(match.params.claimNumber));
  }, [selectClaim, match.params.claimNumber]);

  const handleRefreshForChat = useCallback(() => {
    refreshClaim(decodeUrlClaimNumber(match.params.claimNumber));
  }, [refreshClaim, match.params.claimNumber]);

  const createdHandle = useCallback(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <Dimmer.Dimmable style={{ minHeight: "80%" }}>
      {clonedClaim && <Redirect to={`/claim/${clonedClaim}`} />}
      <PageHeader
        icon="file text"
        title={<ClaimDetailsHeader claim={claim} />}
        breadcrumb={[
          { text: <FormattedMessage id="app.list" />, link: "/claims" },
          { text: <FormattedMessage id="app.details" /> },
        ]}
        buttons={buttons}
        refreshAction={handleRefresh}
        loading={loading}
      />
      <AddAttachment
        triggerOpen={openAddAttachment}
        claimNumber={decodeUrlClaimNumber(match.params.claimNumber)}
        createdSuccess={() => handleAddAttachment()}
      />
      <AddInvoiceCorrection
        createdSuccess={createdHandle}
        invoiceIn={claim?.invoiceNumber || ""}
        invoiceMask={claim?.invoiceDocumentMask || ""}
        setOpenAddCorrectionModal={setAddCorrectionModalOpen}
        triggerOpen={addCorrectionModalOpen}
        claimNumber={claim?.claimNumber || ""}
      />
      {claim && (
        <ClaimTransition
          triggerOpen={openTransition}
          claimCategory={claim.claimCategory}
          claimGroup={claim.claimGroup}
          claimStatus={claim.status}
          grn={claim.grnNumber}
          createdSuccess={() => handleCreateTransition()}
          statusNotes={claim.statusNotes}
          claimNumber={decodeUrlClaimNumber(match.params.claimNumber)}
          lines={claim.claimLines}
          chatTargetGroups={claim.chatTargetGroups}
        />
      )}

      {cardsRendered}
      <ClaimHistory
        triggerOpen={openHistory}
        history={(claim && claim.statuses) || []}
        dictionary={dictionaries[DictionaryName.claimStatus]}
      />
      {claim && (
        <CommentsModal
          open={commentsModalOpen}
          handleClose={setCommentsModalOpen}
          innerMessages={claim.innerMessages}
          clientMessages={claim.clientMessages}
          handleRefresh={handleRefreshForChat}
          sendInnerMessage={sendInnerMessage}
          claimNr={claim.claimNumber}
        />
      )}
      {claim && (
        <AttachmentsModal
          open={openAttachments}
          handleClose={setOpenAttachments}
          claim={claim}
        />
      )}
      {claim && (
        <ClaimCloning
          claim={claim}
          open={claimCloning}
          openModal={setClaimCloning}
          redirectAction={cloneClaimRedirect}
        />
      )}
      <CommonLoader loading={loading || !claim} />
    </Dimmer.Dimmable>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  claims,
  dictionaries,
}: ApplicationState) => {
  return {
    claimIn: claims.selected,
    loading: claims.loadingClaim || dictionaries.loading,
    dictionaries,
  };
};

const mapDispatchToProps: TReduxActions = {
  selectClaim,
  getAttachment,
  fetchDictionary,
  getInvoicePdf,
  getClaimLabel,
  refreshClaim,
  sendInnerMessage,
  handleError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimDetails);
