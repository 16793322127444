import React, { useCallback, useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Header,
  Modal,
  Divider,
  TextArea,
  Dropdown,
  Popup,
} from "semantic-ui-react";
import { ModelFormWrapper } from "~/components/ModelForm/ModelFormWrapper";
import { SubmitButton } from "~/components/ModelForm/SubmitButton";
import { ApplicationState } from "~/store";
import {
  costCoveringProcessing,
  createClaimTransition,
} from "~/store/claims/actions";
import {
  CLAIM_STATUS_ENUM,
  CostCoveringProcessingLine,
  TClaimLine,
} from "~/store/claims/types";
import { clearTransitionClaim } from "~/store/claims/actions";
import VerificationEditLine from "./Transition/VerificationEditLine";
import ClaimLinesTable from "./Transition/ClaimLinesTable";
import TransitionChange from "./Transition/TransitionChange";
import AwaitingDecisionEditLine from "./Transition/AwaitingDecisionEditLine";
import { FormattedMessage } from "react-intl";
import {
  claimCategoriesToDisplayInputs,
  ClaimTransitionAction,
  ClaimTransitionLineActionType,
  FormCategory,
  optionsVerification,
} from "./Transition/transitionColumns.conf";
import { useClaimsRolesRights } from "../../hooks/useClaimsRights";
import { ClaimCategory, CostCoveringGroup } from "../../lib/claimTypes";
import { useChangeLine } from "./hooks/useChangeLine";
import ActorsEditLine from "./Transition/ActorsEditLine";

type TReduxActions = {
  createClaimTransition: typeof createClaimTransition;
  clearTransitionClaim: typeof clearTransitionClaim;
  costCoveringProcessing: typeof costCoveringProcessing;
};

interface PropsFromState {
  transitionChanged: boolean;
}

interface ClaimTransitionLine {
  statusNotes: string;
  actions: any;
  lineNumber: number;
}

type Props = {
  triggerOpen: boolean;
  claimNumber: string;
  lines: TClaimLine[];
  statusNotes: string;
  createdSuccess: () => void;
  claimStatus: string;
  claimGroup: string;
  claimCategory: ClaimCategory;
  grn?: string;
  chatTargetGroups: string[];
};

type TClaimTransitionProps = TReduxActions & Props & PropsFromState;

const ClaimTransition: React.FC<TClaimTransitionProps> = ({
  children,
  triggerOpen,
  claimNumber,
  lines,
  statusNotes,
  createClaimTransition,
  transitionChanged,
  createdSuccess,
  clearTransitionClaim,
  claimStatus,
  claimGroup,
  claimCategory,
  grn,
  chatTargetGroups,
  costCoveringProcessing,
}) => {
  const initTransitionAction =
    claimStatus === "AWAITING_DECISION"
      ? ClaimTransitionAction.DECISION
      : claimStatus;
  const {
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
  } = useClaimsRolesRights();

  const [open, setOpen] = useState<boolean>(false);
  const [transitionLines, setTransitionLines] = useState<TClaimLine[]>([]);
  const [transitionLineIndex, setTransitionLineIndex] = useState<number | null>(
    null
  );
  const [claimNote, setClaimNotes] = useState<string>(statusNotes);
  const [transitionAction, setTransitionAction] = useState<string>("");
  const [confirmOption, setConfirmOption] = useState<string>("");
  const [transitionActionStatus] = useState<string>(initTransitionAction);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [claimNoteWarehouse, setClaimNotesWarehouse] = useState<string>("");
  const [claimNoteTransport, setClaimNotesTransport] = useState<string>("");

  const defaultCostGroup = useMemo(() => {
    if (userHaveLdcUiWarehouseClaimsProcessRole) {
      return CostCoveringGroup.warehouse;
    } else if (userHaveLdcUiTransportClaimsProcessRole) {
      return CostCoveringGroup.transport;
    } else {
      return CostCoveringGroup.distrigo;
    }
  }, [
    userHaveLdcUiTransportClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
  ]);

  const employeeProcessingAvailable = useMemo(
    () => !!(chatTargetGroups && chatTargetGroups.length),
    [chatTargetGroups]
  );

  useEffect(() => {
    if (triggerOpen) {
      setOpen(true);
    }
  }, [triggerOpen]);

  const {
    changeLine,
    changeLineCostGroup,
    isValidQuantity,
    isValidQuantityBOK,
    orZero,
  } = useChangeLine({
    transitionLineIndex,
    transitionLines,
    setTransitionLines,
    claimLines: lines,
    claimStatus: claimStatus as CLAIM_STATUS_ENUM,
  });

  const costCoveringGroupProcessing = useMemo(
    () =>
      userHaveLdcUiTransportClaimsProcessRole ||
      userHaveLdcUiWarehouseClaimsProcessRole,
    [
      userHaveLdcUiTransportClaimsProcessRole,
      userHaveLdcUiWarehouseClaimsProcessRole,
    ]
  );

  const initialLinesMapping = useCallback(() => {
    let newLines = [...lines];
    if (costCoveringGroupProcessing) {
      const countAccpetedQuantity = (line: TClaimLine): number => {
        return userHaveLdcUiClaimsProcessRole
          ? line.claimedQuantity - orZero(line.toReceiveQuantity)
          : orZero(line.acceptedQuantity);
      };
      newLines = newLines
        .map((line) => ({
          ...line,
          acceptedQuantity: countAccpetedQuantity(line),
          acceptedQuantityByTransport: orZero(line.acceptedQuantityByTransport),
          acceptedQuantityByWarehouse: orZero(line.acceptedQuantityByWarehouse),
          requestReturnQuantity: 0, //orZero(line.toReceiveQuantity),
          rejectQuantity: 0,
          isNotValidQuantity: userHaveLdcUiClaimsProcessRole
            ? !isValidQuantityBOK(line)
            : !isValidQuantity(line),
          selectedCostGroup: defaultCostGroup,
        }))
        .map((line) => ({
          ...line,
          isNotValidQuantity: userHaveLdcUiClaimsProcessRole
            ? !isValidQuantityBOK(line)
            : !isValidQuantity(line),
        }));
    } else {
      if (
        transitionActionStatus === ClaimTransitionAction.DECISION &&
        claimCategory === ClaimCategory.surplusWithReturn
      ) {
        newLines.forEach((line) => {
          line[FormCategory.RECEIVED_FULL_VALUE] = {
            claimedQuantity: line.receivedQuantityFullValue,
            acceptedQuantity: 0,
            decisionAcceptedWithoutCorrectionQuantity:
              line.receivedQuantityFullValue,
            decisionRejectedToClientQuantity: 0,
            decisionToDestroyQuantity: 0,
            decisionToDestroyWithoutCorrectionQuantity: 0,
            isNotValidQuantity: false,
          };

          line[FormCategory.RECEIVED_DEFECTIVE] = {
            claimedQuantity: line.receivedQuantityDefective,
            acceptedQuantity: 0,
            decisionAcceptedWithoutCorrectionQuantity: 0,
            decisionRejectedToClientQuantity: 0,
            decisionToDestroyQuantity: 0,
            decisionToDestroyWithoutCorrectionQuantity: 0,
            isNotValidQuantity: line.receivedQuantityDefective
              ? `Suma musi byc równa ${line.receivedQuantityDefective}`
              : false,
          };
        });
      } else {
        newLines.forEach((line) => {
          line[FormCategory.RECEIVED_FULL_VALUE] = {
            claimedQuantity: line.receivedQuantityFullValue,
            acceptedQuantity: line.receivedQuantityFullValue,
            decisionAcceptedWithoutCorrectionQuantity: 0,
            decisionRejectedToClientQuantity: 0,
            decisionToDestroyQuantity: 0,
            decisionToDestroyWithoutCorrectionQuantity: 0,
            isNotValidQuantity: false,
          };

          line[FormCategory.RECEIVED_DEFECTIVE] = {
            claimedQuantity: line.receivedQuantityDefective,
            acceptedQuantity: 0,
            decisionAcceptedWithoutCorrectionQuantity: 0,
            decisionRejectedToClientQuantity: 0,
            decisionToDestroyQuantity: 0,
            decisionToDestroyWithoutCorrectionQuantity: 0,
            isNotValidQuantity: line.receivedQuantityDefective
              ? `Suma musi byc równa ${line.receivedQuantityDefective}`
              : false,
          };
        });
      }

      if (transitionActionStatus === ClaimTransitionAction.VERIFICATION) {
        const countAccpetedQuantity = (line: TClaimLine): number => {
          return userHaveLdcUiClaimsProcessRole
            ? line.claimedQuantity - orZero(line.toReceiveQuantity)
            : orZero(line.acceptedQuantity);
        };

        if (employeeProcessingAvailable) {
          newLines = newLines
            .map((line) => ({
              ...line,
              acceptedQuantity: countAccpetedQuantity(line),
              acceptedQuantityByTransport: orZero(
                line.acceptedQuantityByTransport
              ),
              acceptedQuantityByWarehouse: orZero(
                line.acceptedQuantityByWarehouse
              ),
              requestReturnQuantity: orZero(line.toReceiveQuantity),
              rejectQuantity: 0,
              isNotValidQuantity: userHaveLdcUiClaimsProcessRole
                ? !isValidQuantityBOK(line)
                : !isValidQuantity(line),
              selectedCostGroup: defaultCostGroup,
            }))
            .map((line) => ({
              ...line,
              isNotValidQuantity: userHaveLdcUiClaimsProcessRole
                ? !isValidQuantityBOK(line)
                : !isValidQuantity(line),
            }));
        } else {
          newLines = newLines.map((line) => ({
            ...line,
            acceptedQuantity: claimCategoriesToDisplayInputs["VERIFICATION"][
              "VERIFICATION_ACCEPTED"
            ].includes(claimCategory)
              ? line.claimedQuantity
              : 0,
            requestReturnQuantity: 0,
            rejectQuantity: 0,
            isNotValidQuantity: claimCategoriesToDisplayInputs["VERIFICATION"][
              "VERIFICATION_ACCEPTED"
            ].includes(claimCategory)
              ? false
              : `Suma musi byc równa ${line.claimedQuantity}`,
          }));
        }
      }
      // "RK1_ZRPN", "RK2_ZRNF"
      if (
        [ClaimCategory.newPartReturn, ClaimCategory.surplusWithReturn].includes(
          claimCategory
        )
      ) {
        newLines = newLines.map((line) => {
          return {
            ...line,
            acceptedQuantity: 0,
            rejectQuantity: 0,
            requestReturnQuantity: line.claimedQuantity,
            isNotValidQuantity: false,
          };
        });
      }
    }
    setTransitionLines(newLines);
    setClaimNotesTransport("");
    setClaimNotesWarehouse("");
  }, [
    lines,
    claimCategory,
    defaultCostGroup,
    employeeProcessingAvailable,
    isValidQuantity,
    isValidQuantityBOK,
    transitionActionStatus,
    userHaveLdcUiClaimsProcessRole,
    costCoveringGroupProcessing,
    orZero,
  ]);

  const isWarehouseCommentRequired = useMemo(() => {
    if (!userHaveLdcUiWarehouseClaimsProcessRole) return false;
    let isWarehouseChecked = false;
    transitionLines.forEach((line) => {
      if (line.selectedCostGroup === CostCoveringGroup.warehouse) {
        isWarehouseChecked = true;
      }
    });
    return isWarehouseChecked;
  }, [userHaveLdcUiWarehouseClaimsProcessRole, transitionLines]);

  const isTransportCommentRequired = useMemo(() => {
    if (!userHaveLdcUiTransportClaimsProcessRole) return false;
    let isTransportChecked = false;
    transitionLines.forEach((line) => {
      if (line.selectedCostGroup === CostCoveringGroup.transport) {
        isTransportChecked = true;
      }
    });
    return isTransportChecked;
  }, [userHaveLdcUiTransportClaimsProcessRole, transitionLines]);

  useEffect(() => {
    if (costCoveringGroupProcessing) {
      const isValidForm = transitionLines.every(
        (line) => line.isNotValidQuantity === false
      );

      if (isValidForm) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }

      if (isTransportCommentRequired && !claimNoteTransport.length) {
        setDisableSubmit(true);
      }
      if (isWarehouseCommentRequired && !claimNoteWarehouse.length) {
        setDisableSubmit(true);
      }
    } else {
      if (transitionActionStatus === ClaimTransitionAction.VERIFICATION) {
        const isValidForm = transitionLines.every(
          (line) => line.isNotValidQuantity === false
        );

        if (isValidForm) {
          setDisableSubmit(false);
        } else {
          setDisableSubmit(true);
        }

        if (
          userHaveLdcUiTransportClaimsProcessRole &&
          !claimNoteTransport.length
        ) {
          setDisableSubmit(true);
        }
        if (
          userHaveLdcUiWarehouseClaimsProcessRole &&
          !claimNoteWarehouse.length
        ) {
          setDisableSubmit(true);
        }
        if (!costCoveringGroupProcessing && (!claimNote || !claimNote.length)) {
          setDisableSubmit(true);
        }
      }

      if (transitionActionStatus === ClaimTransitionAction.DECISION) {
        const isValidForm = transitionLines.every(
          (line) =>
            line[FormCategory.RECEIVED_FULL_VALUE].isNotValidQuantity ===
              false &&
            line[FormCategory.RECEIVED_DEFECTIVE].isNotValidQuantity === false
        );
        if (isValidForm) {
          setDisableSubmit(false);
        } else {
          setDisableSubmit(true);
        }
      }

      if (transitionActionStatus === ClaimTransitionAction.ACCEPTED) {
        setDisableSubmit(!claimNote || !claimNote.length);
      }
    }

    // eslint-disable-next-line
  }, [
    transitionLines,
    lines,
    claimNote,
    claimNoteTransport,
    claimNoteWarehouse,
    userHaveLdcUiTransportClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
    costCoveringGroupProcessing,
    isTransportCommentRequired,
    isWarehouseCommentRequired,
  ]);

  useEffect(() => {
    initialLinesMapping();
    setTransitionLineIndex(null);
    // eslint-disable-next-line
  }, [lines]);

  const transitionLinesMapped = useCallback(
    (
      acceptAllLines: boolean = false,
      type: string = ""
    ): ClaimTransitionLine[] | undefined => {
      if (transitionAction === ClaimTransitionAction.MARK_CLOSED) return;

      if (
        transitionActionStatus === ClaimTransitionAction.VERIFICATION &&
        acceptAllLines === false
      ) {
        return transitionLines.map((line) => {
          const actions = [];
          if (
            line.acceptedQuantity !== 0 &&
            !["RK1_ZRPN", "RK2_ZRNF"].includes(claimCategory)
          )
            actions.push({
              type: ClaimTransitionLineActionType.VERIFICATION_ACCEPTED,
              quantity: line.acceptedQuantity || line.claimedQuantity,
            });

          if (line.requestReturnQuantity)
            actions.push({
              type: ClaimTransitionLineActionType.VERIFICATION_REQUEST_RETURN,
              quantity: line.requestReturnQuantity,
            });

          if (line.rejectQuantity)
            actions.push({
              type: ClaimTransitionLineActionType.VERIFICATION_REJECTED,
              quantity: line.rejectQuantity,
            });

          return {
            lineNumber: line.lineNumber,
            statusNotes: line.statusNotes,
            actions,
          };
        });
      }

      if (
        transitionActionStatus === ClaimTransitionAction.VERIFICATION &&
        acceptAllLines === true
      ) {
        return transitionLines.map((line) => {
          return {
            lineNumber: line.lineNumber,
            statusNotes: line.statusNotes,
            actions: [
              {
                type,
                quantity: line.claimedQuantity,
              },
            ],
          };
        });
      }

      if (
        transitionActionStatus === ClaimTransitionAction.DECISION &&
        acceptAllLines === false
      ) {
        return transitionLines
          .filter(
            (line) => line.toReceiveQuantity && line.toReceiveQuantity > 0
          )
          .map((line) => {
            const actions = [];
            if (
              line.RECEIVED_FULL_VALUE &&
              line.RECEIVED_FULL_VALUE.acceptedQuantity !== 0
            ) {
              actions.push({
                type: ClaimTransitionLineActionType.DECISION_ACCEPTED,
                quantity:
                  line.RECEIVED_FULL_VALUE.acceptedQuantity ||
                  line.receivedQuantityFullValue,
                target: "RECEIVED_FULL_VALUE",
              });
            }

            if (!line.RECEIVED_FULL_VALUE && line.receivedQuantityFullValue) {
              actions.push({
                type: ClaimTransitionLineActionType.DECISION_ACCEPTED,
                quantity: line.receivedQuantityFullValue,
                target: "RECEIVED_FULL_VALUE",
              });
            }

            if (
              line.RECEIVED_FULL_VALUE &&
              line.RECEIVED_FULL_VALUE.decisionAcceptedWithoutCorrectionQuantity
            )
              actions.push({
                type: ClaimTransitionLineActionType.DECISION_ACCEPTED_WITHOUT_CORRECTION,
                quantity:
                  line.RECEIVED_FULL_VALUE
                    .decisionAcceptedWithoutCorrectionQuantity,
                target: "RECEIVED_FULL_VALUE",
              });

            if (
              line.RECEIVED_FULL_VALUE &&
              line.RECEIVED_FULL_VALUE.decisionToDestroyQuantity
            )
              actions.push({
                type: ClaimTransitionLineActionType.DECISION_TO_DESTROY,
                quantity: line.RECEIVED_FULL_VALUE.decisionToDestroyQuantity,
                target: "RECEIVED_FULL_VALUE",
              });

            if (
              line.RECEIVED_FULL_VALUE &&
              line.RECEIVED_FULL_VALUE
                .decisionToDestroyWithoutCorrectionQuantity
            )
              actions.push({
                type: ClaimTransitionLineActionType.DECISION_TO_DESTROY_WITHOUT_CORRECTION,
                quantity:
                  line.RECEIVED_FULL_VALUE
                    .decisionToDestroyWithoutCorrectionQuantity,
                target: "RECEIVED_FULL_VALUE",
              });

            if (
              line.RECEIVED_FULL_VALUE &&
              line.RECEIVED_FULL_VALUE.decisionRejectedToClientQuantity
            )
              actions.push({
                type: ClaimTransitionLineActionType.DECISION_REJECTED_TO_CLIENT,
                quantity:
                  line.RECEIVED_FULL_VALUE.decisionRejectedToClientQuantity,
                target: "RECEIVED_FULL_VALUE",
              });

            if (!line.RECEIVED_DEFECTIVE && line.receivedQuantityDefective) {
              actions.push({
                type: ClaimTransitionLineActionType.DECISION_ACCEPTED,
                quantity: line.receivedQuantityDefective,
                target: "RECEIVED_DEFECTIVE",
              });
            }

            if (
              line.RECEIVED_DEFECTIVE &&
              line.RECEIVED_DEFECTIVE.acceptedQuantity !== 0
            )
              actions.push({
                type: ClaimTransitionLineActionType.DECISION_ACCEPTED,
                quantity:
                  line.RECEIVED_DEFECTIVE.acceptedQuantity ||
                  line.receivedQuantityDefective,
                target: "RECEIVED_DEFECTIVE",
              });

            if (
              line.RECEIVED_DEFECTIVE &&
              line.RECEIVED_DEFECTIVE.decisionAcceptedWithoutCorrectionQuantity
            )
              actions.push({
                type: ClaimTransitionLineActionType.DECISION_ACCEPTED_WITHOUT_CORRECTION,
                quantity:
                  line.RECEIVED_DEFECTIVE
                    .decisionAcceptedWithoutCorrectionQuantity,
                target: "RECEIVED_DEFECTIVE",
              });

            if (
              line.RECEIVED_DEFECTIVE &&
              line.RECEIVED_DEFECTIVE.decisionToDestroyQuantity
            )
              actions.push({
                type: ClaimTransitionLineActionType.DECISION_TO_DESTROY,
                quantity: line.RECEIVED_DEFECTIVE.decisionToDestroyQuantity,
                target: "RECEIVED_DEFECTIVE",
              });

            if (
              line.RECEIVED_DEFECTIVE &&
              line.RECEIVED_DEFECTIVE.decisionToDestroyWithoutCorrectionQuantity
            )
              actions.push({
                type: ClaimTransitionLineActionType.DECISION_TO_DESTROY_WITHOUT_CORRECTION,
                quantity:
                  line.RECEIVED_DEFECTIVE
                    .decisionToDestroyWithoutCorrectionQuantity,
                target: "RECEIVED_DEFECTIVE",
              });

            if (
              line.RECEIVED_DEFECTIVE &&
              line.RECEIVED_DEFECTIVE.decisionRejectedToClientQuantity
            )
              actions.push({
                type: ClaimTransitionLineActionType.DECISION_REJECTED_TO_CLIENT,
                quantity:
                  line.RECEIVED_DEFECTIVE.decisionRejectedToClientQuantity,
                target: "RECEIVED_DEFECTIVE",
              });

            return {
              lineNumber: line.lineNumber,
              statusNotes: line.statusNotes,
              actions,
            };
          });
      }
    },
    [claimCategory, transitionAction, transitionActionStatus, transitionLines]
  );

  const transitionCostCoveringLinesMapped =
    useCallback((): CostCoveringProcessingLine[] => {
      return transitionLines.map((line) => ({
        lineNumber: line.lineNumber,
        quantity:
          line.selectedCostGroup === CostCoveringGroup.transport
            ? line.acceptedQuantityByTransport
            : line.acceptedQuantityByWarehouse,
        costCoveringGroup: line.selectedCostGroup,
      }));
    }, [transitionLines]);

  const filterActions = (option: string) => {
    if (!claimCategoriesToDisplayInputs["VERIFICATION"][option]) return false;
    if (
      claimCategoriesToDisplayInputs["VERIFICATION"][option].includes(
        claimCategory
      )
    )
      return true;
  };

  const acceptAllLines = (type: string) => {
    createClaimTransition(
      claimNumber,
      transitionAction || transitionActionStatus,
      claimNote,
      transitionLinesMapped(true, type)
    );
  };

  const handleCancel = useCallback((): void => {
    setOpen(false);
    initialLinesMapping();
  }, [initialLinesMapping]);

  const handleConfirm = useCallback((): void => {
    if (userHaveLdcUiClaimsProcessRole) {
      createClaimTransition(
        claimNumber,
        transitionAction || transitionActionStatus,
        claimNote,
        transitionLinesMapped()
      );
    }
    if (
      userHaveLdcUiTransportClaimsProcessRole ||
      userHaveLdcUiWarehouseClaimsProcessRole
    ) {
      const lines = transitionCostCoveringLinesMapped();
      costCoveringProcessing(
        lines,
        claimNumber,
        isWarehouseCommentRequired ? claimNoteWarehouse : null,
        isTransportCommentRequired ? claimNoteTransport : null,
        handleCancel
      );
    }
  }, [
    claimNote,
    claimNoteTransport,
    claimNoteWarehouse,
    claimNumber,
    costCoveringProcessing,
    createClaimTransition,
    handleCancel,
    isWarehouseCommentRequired,
    isTransportCommentRequired,
    transitionAction,
    transitionActionStatus,
    transitionCostCoveringLinesMapped,
    transitionLinesMapped,
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
  ]);

  const handleEdit = (index: number): void => setTransitionLineIndex(index);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleConfirmOption = (e: any, data: any): void =>
    setConfirmOption(data.value);

  if (transitionChanged && open) {
    clearTransitionClaim();
    createdSuccess();
    handleCancel();
    setTransitionLines(lines);
    setTransitionAction("");
    setOpen(false);
  }

  const validationMessage = useCallback(() => {
    if (disableSubmit) {
      if (isWarehouseCommentRequired && !claimNoteWarehouse.length) {
        return "Komentarz aktora Magazyn jest wymagany";
      } else if (isTransportCommentRequired && !claimNoteTransport.length) {
        return "Komentarz aktora Transport jest wymagany";
      } else if (
        !costCoveringGroupProcessing &&
        (!claimNote || !claimNote.length)
      ) {
        return "Komentarz jest wymagany";
      } else {
        return "Status ilości na liniach jest niewłaściwy";
      }
    }

    return null;
  }, [
    disableSubmit,
    claimNote,
    claimNoteWarehouse.length,
    claimNoteTransport.length,
    costCoveringGroupProcessing,
    isTransportCommentRequired,
    isWarehouseCommentRequired,
  ]);

  const showTransitionForActors = useCallback(() => {
    return (
      userHaveLdcUiWarehouseClaimsProcessRole ||
      userHaveLdcUiTransportClaimsProcessRole
    );
  }, [
    userHaveLdcUiWarehouseClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
  ]);

  return (
    <Modal
      size="large"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <ModelFormWrapper onSubmit={handleConfirm}>
        <Header icon="edit" content={`Tranzycja reklamacji ${claimNumber}`} />
        {showTransitionForActors() ? (
          <Modal.Content>
            <Form>
              <Form.Group>
                {costCoveringGroupProcessing &&
                userHaveLdcUiWarehouseClaimsProcessRole ? (
                  <Form.Field
                    required={isWarehouseCommentRequired}
                    width={userHaveLdcUiTransportClaimsProcessRole ? 8 : 16}
                  >
                    <label>Komentarz do reklamacji - Magazyn</label>
                    <TextArea
                      placeholder="Wypełnij opis"
                      required={isWarehouseCommentRequired}
                      value={claimNoteWarehouse}
                      onChange={(e, d: any) => setClaimNotesWarehouse(d.value)}
                    />
                  </Form.Field>
                ) : null}
                {costCoveringGroupProcessing &&
                userHaveLdcUiTransportClaimsProcessRole ? (
                  <Form.Field
                    required={isTransportCommentRequired}
                    width={userHaveLdcUiWarehouseClaimsProcessRole ? 8 : 16}
                  >
                    <label>Komentarz do reklamacji - Transport</label>
                    <TextArea
                      placeholder="Wypełnij opis"
                      required={isTransportCommentRequired}
                      value={claimNoteTransport}
                      onChange={(e, d: any) => setClaimNotesTransport(d.value)}
                    />
                  </Form.Field>
                ) : null}
              </Form.Group>
            </Form>

            <ClaimLinesTable
              transitionLines={transitionLines}
              handleEdit={handleEdit}
              transitionActionStatus={transitionActionStatus}
              claimCategory={claimCategory}
              chatTargetGroups={chatTargetGroups}
              claimStatus={claimStatus as CLAIM_STATUS_ENUM}
            />
            <Divider />
            <ActorsEditLine
              claimCategory={claimCategory}
              claimGroup={claimGroup}
              transitionLineIndex={transitionLineIndex}
              transitionLines={transitionLines}
              changeLine={changeLineCostGroup}
              chatTargetGroups={chatTargetGroups}
            />
          </Modal.Content>
        ) : (
          <Modal.Content>
            {!(
              transitionActionStatus === ClaimTransitionAction.VERIFICATION ||
              transitionActionStatus === ClaimTransitionAction.DECISION
            ) && (
              <>
                <TransitionChange
                  claimStatus={claimStatus}
                  claimGroup={claimGroup}
                  grn={grn}
                  setTransitionAction={setTransitionAction}
                  transitionAction={transitionAction}
                />
                <Divider />
              </>
            )}

            <Form>
              {!costCoveringGroupProcessing ? (
                <Form.Field required={true}>
                  <label>Komentarz do reklamacji</label>
                  <TextArea
                    placeholder="Wypełnij opis"
                    value={claimNote ? claimNote : ""}
                    onChange={(e, d: any) => setClaimNotes(d.value)}
                  />
                </Form.Field>
              ) : null}
            </Form>

            {transitionActionStatus === ClaimTransitionAction.VERIFICATION ||
            transitionActionStatus === ClaimTransitionAction.DECISION ? (
              <ClaimLinesTable
                transitionLines={transitionLines}
                handleEdit={handleEdit}
                transitionActionStatus={transitionActionStatus}
                claimCategory={claimCategory}
                chatTargetGroups={chatTargetGroups}
                claimStatus={claimStatus as CLAIM_STATUS_ENUM}
              />
            ) : null}
            <Divider />
            {transitionLineIndex !== null &&
            transitionActionStatus === ClaimTransitionAction.VERIFICATION ? (
              <VerificationEditLine
                claimCategory={claimCategory}
                claimGroup={claimGroup}
                transitionLineIndex={transitionLineIndex}
                transitionLines={transitionLines}
                changeLine={
                  !costCoveringGroupProcessing
                    ? changeLine
                    : changeLineCostGroup
                }
                chatTargetGroups={chatTargetGroups}
              />
            ) : null}
            {transitionLineIndex !== null &&
            transitionActionStatus === ClaimTransitionAction.DECISION ? (
              <AwaitingDecisionEditLine
                claimCategory={claimCategory}
                claimGroup={claimGroup}
                transitionLineIndex={transitionLineIndex}
                transitionLines={transitionLines}
                changeLine={changeLine}
                formCategory={FormCategory.RECEIVED_FULL_VALUE}
                claimedQuantity={
                  transitionLines[transitionLineIndex]
                    .receivedQuantityFullValue || 0
                }
              />
            ) : null}
            {transitionLineIndex !== null &&
            transitionActionStatus === ClaimTransitionAction.DECISION ? (
              <AwaitingDecisionEditLine
                claimCategory={claimCategory}
                claimGroup={claimGroup}
                transitionLineIndex={transitionLineIndex}
                transitionLines={transitionLines}
                changeLine={changeLine}
                formCategory={FormCategory.RECEIVED_DEFECTIVE}
                claimedQuantity={
                  transitionLines[transitionLineIndex]
                    .receivedQuantityDefective || 0
                }
              />
            ) : null}
          </Modal.Content>
        )}

        <Modal.Actions>
          {transitionActionStatus === "VERIFICATION" && (
            <>
              {!costCoveringGroupProcessing ? (
                <Button
                  icon="check"
                  disabled={!confirmOption}
                  content="Zatwierdź wszystko jako:"
                  labelPosition="right"
                  primary
                  onClick={() => acceptAllLines(confirmOption)}
                />
              ) : null}
              {!costCoveringGroupProcessing ? (
                <Dropdown
                  placeholder="Typ masowej operacji"
                  selection
                  value={confirmOption}
                  onChange={handleConfirmOption}
                  options={optionsVerification.filter((option) =>
                    filterActions(option.key)
                  )}
                  noResultsMessage={
                    <FormattedMessage id="app.noResultsMessage" />
                  }
                />
              ) : null}
            </>
          )}
          {disableSubmit ? (
            <Popup
              trigger={
                <span>
                  <SubmitButton
                    content="Zatwierdź"
                    icon="add"
                    labelPosition="right"
                    primary
                    loading={false}
                    disabled={disableSubmit}
                  />
                </span>
              }
            >
              {validationMessage()}
            </Popup>
          ) : (
            <SubmitButton
              content="Zatwierdź"
              icon="add"
              labelPosition="right"
              primary
              loading={false}
              disabled={disableSubmit}
            />
          )}
          <Button content="Anuluj" onClick={handleCancel} />
        </Modal.Actions>
      </ModelFormWrapper>
    </Modal>
  );
};

const mapStateToProps = ({ claims }: ApplicationState) => {
  return {
    transitionChanged: claims.transitionChanged,
  };
};

const mapDispatchToProps: TReduxActions = {
  createClaimTransition,
  clearTransitionClaim,
  costCoveringProcessing,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimTransition);
