import { TColumn } from "~/components/Table/lib/types";
import { TotalRecords } from "~/store/types";
import { downloadData } from "~/utils/downloadData";
import { exportFile } from "~/utils/exportFile";

type Props = {
  tableKey: string;
  totalRecords: TotalRecords;
  exportPercent: number;
  setExportPercent: React.Dispatch<React.SetStateAction<number>>;
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
  params: any;
  selectedTypeOfDataToExport: string | undefined;
  columns: TColumn[];
  columnsWithInvoiceLine: TColumn[];
};

export const useHandleDownloadModal = ({
  tableKey,
  totalRecords,
  exportPercent,
  setExportPercent,
  setDisableButton,
  params,
  selectedTypeOfDataToExport,
  columns,
  columnsWithInvoiceLine,
}: Props) => {
  const setPercent = (percent: number): void => {
    let exportPercentVal = exportPercent + Math.round(percent * 10) / 10;
    exportPercent = Math.round(exportPercentVal * 10) / 10;
    setExportPercent(Math.round(exportPercentVal * 10) / 10);
  };

  const handleOpenDownloadModal = async (): Promise<void> => {
    let numberOfRecordsToExport;
    if (totalRecords && totalRecords.total) {
      numberOfRecordsToExport =
        totalRecords.total < 10000 ? totalRecords.total : 10000;
    }
    let pageToExport = numberOfRecordsToExport
      ? Math.round(numberOfRecordsToExport / 100 + 0.49)
      : 0;
    let dataExport = [];
    let dataToExport: any[];
    dataToExport = [];
    if (pageToExport <= 0) {
      setExportPercent(100);
    } else {
      setExportPercent(0);
    }
    setPercent(0);
    for (let page = 1; page <= pageToExport; page++) {
      setDisableButton(true);
      if (pageToExport === page) {
        setExportPercent(100);
      }
      dataExport.push(
        await downloadData(
          tableKey,
          page - 1,
          { ...params, filterLabels: params.filters || [] },
          setPercent(100 / pageToExport)
        )
      );
      if (page > 1) {
        dataToExport = dataToExport.concat(dataExport[0]);
        dataExport = [];
      } else {
        dataToExport = dataExport[0];
        dataExport = [];
      }
    }

    exportFile(
      "Zamowienia",
      dataToExport,
      selectedTypeOfDataToExport === "Dokument"
        ? columns
        : columnsWithInvoiceLine,
      selectedTypeOfDataToExport !== "Dokument",
      selectedTypeOfDataToExport === "Dokument"
    );
    setDisableButton(false);
    setExportPercent(0);
  };

  return { handleOpenDownloadModal };
};
