import React, { useState, SyntheticEvent, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import {
  fetchInvoices,
  createInvoiceCorrection,
  clearCreated,
  fetchFinalInvoice,
  clearFinalInvoice,
  fetchDebitNotes,
} from "~/store/invoices/actions";
import { IInvoice, TInvoicePreviousLine } from "~/store/invoices/types";
import {
  DictionaryItem,
  DictionariesState,
  DictionaryName,
} from "~/store/dictionaries/types";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { toastWarn } from "~/utils/toast";
import { reducer } from "~/utils/reducer";

import AddInvoiceCorrectionContent from "./AddInvoiceCorrectionContent/AddInvoiceCorrectionContent";
import { getEndecodedClaimNumber } from "~/store/claims/sagas";
import { callApi } from "~/utils/api";

type TReduxState = {
  invoices: IInvoice[];
  loadingInvoices: boolean;
  loadingDictionaries: boolean;
  created?: boolean;
  creating?: boolean;
  dictionaries: DictionariesState;
  finalInvoice?: IInvoice;
  debitNotes: IInvoice[];
};

type TReduxActions = {
  createInvoiceCorrection: typeof createInvoiceCorrection;
  clearCreated: typeof clearCreated;
  fetchInvoices: typeof fetchInvoices;
  fetchDictionary: typeof fetchDictionary;
  fetchFinalInvoice: typeof fetchFinalInvoice;
  clearFinalInvoice: typeof clearFinalInvoice;
  fetchDebitNotes: typeof fetchDebitNotes;
};

type TProps = {
  createdSuccess: () => void;
  triggerOpen: boolean;
  invoiceIn?: string;
  invoiceMask?: string;
  isDebitNote?: boolean;
  setOpenAddCorrectionModal?: React.Dispatch<React.SetStateAction<boolean>>;
  correctionForClaim?: string;
  claimNumber?: string;
};

export type TLine = {
  correctionBlockade: "NONE" | "EXPIRED_INVOICE";
  lineNumber: number;
  referenceId: string;
  quantity: number;
  quantityOrgin: number;
  discount: number;
  vat: number;
  taxRate: number;
  price: number;
  selected: boolean;
  unitPriceNetBeforeDiscount: number;
  unitPriceNetAfterDiscount: number;
  previewDiscount?: number;
  orderNumber?: string;
  packageNumber: string | null;
};

type TAddInvoiceCorrectionProps = TReduxState & TReduxActions & TProps;

type CorrectQuantity = {
  lineNumber: number;
  referenceId: string;
  orderNumber: string;
  packageNumber: string;
  quantity: number;
  taxRate: number;
  unitPriceNetBeforeDiscount: number;
  unitPriceNetAfterDiscount: number;
  discount: number;
};

enum ShownModal {
  NONE,
  CONFIRM_CORRECTION,
  CONFIRM_RESET_CORRECTION,
}

const AddInvoiceCorrection: React.FC<TAddInvoiceCorrectionProps> = ({
  children,
  triggerOpen,
  created,
  creating,
  invoices,
  debitNotes,
  createInvoiceCorrection,
  createdSuccess,
  clearCreated,
  fetchInvoices,
  fetchDictionary,
  invoiceIn,
  finalInvoice,
  isDebitNote,
  fetchFinalInvoice,
  clearFinalInvoice,
  fetchDebitNotes,
  setOpenAddCorrectionModal,
  invoiceMask,
  correctionForClaim,
  claimNumber,
}) => {
  const [changed, setChanged] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isResetCorrection, setIsResetCorrection] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<string>("");
  const [selectedInvoiceMask, setSelectedInvoiceMask] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [lines, setLines] = useState<TLine[]>([]);
  const [previousLines, setPreviousLines] = useState<TInvoicePreviousLine[]>(
    []
  );
  const [mappedFinalInvoice, setMappedFinalInvoice] = useState<
    any | undefined
  >();
  const [correctQuantities, setCorrectQuantities] = useState<
    CorrectQuantity[] | undefined
  >(undefined);
  const [tableData, setTableData] = React.useReducer(reducer, {
    data: lines,
    direction: null,
    loading: false,
    checkedLine: null,
    selected: null,
    column: null,
  });
  const timeoutRef = React.useRef();
  const [shownModal, setShownModal] = useState<ShownModal>(ShownModal.NONE);

  const getCorrectQuantities = useCallback(async () => {
    try {
      if (!claimNumber) return;
      const res: { quantities: any[] } = await callApi(
        "GET",
        `/claims/${getEndecodedClaimNumber(claimNumber)}/correction-quantities`
      );
      if (res && "quantities" in res && !lines.length) {
        setCorrectQuantities(res.quantities);
      }
    } catch (e) {}
  }, [claimNumber, lines]);

  useEffect(() => {
    if (invoiceMask) {
      setSelectedInvoiceMask(invoiceMask);
    }
  }, [invoiceMask]);

  useEffect(() => {
    if (claimNumber) {
      setDescription(`${claimNumber} - `);
    }
  }, [claimNumber]);

  useEffect(() => {
    if (open && correctQuantities === undefined) {
      getCorrectQuantities();
    }
  }, [open, correctQuantities, getCorrectQuantities]);

  useEffect(() => {
    if (correctQuantities && finalInvoice && open && !mappedFinalInvoice) {
      const linesToSet = finalInvoice.finalInvoiceLines
        .map((line: any) => ({
          lineNumber: line.lineNumber,
          referenceId: line.referenceId,
          quantity: correctQuantities.find(
            (item) => item.lineNumber === line.lineNumber
          )?.quantity,
          quantityOrgin: correctQuantities.find(
            (item) => item.lineNumber === line.lineNumber
          )?.quantity,
          vat: line.taxRate,
          taxRate: line.taxRate,
          discount: line.discount,
          price: line.unitPriceNetBeforeDiscount
            ? line.unitPriceNetBeforeDiscount
            : 0,
          selected: false,
          unitPriceNetBeforeDiscount: line.unitPriceNetBeforeDiscount || 0,
          unitPriceNetAfterDiscount: line.unitPriceNetAfterDiscount || 0,
          previewDiscount: getPercentageChange(
            line.unitPriceNetBeforeDiscount,
            line.unitPriceNetAfterDiscount
          ),
          orderNumber: line.orderNumber,
        }))
        .filter((item: any) =>
          correctQuantities.find(
            (correctItem) => correctItem.lineNumber === item.lineNumber
          )
        );

      setMappedFinalInvoice({
        ...finalInvoice,
        finalInvoiceLines: linesToSet,
      });
    }
  }, [correctQuantities, finalInvoice, open, mappedFinalInvoice]);

  useEffect(() => {
    open && fetchDictionary(DictionaryName.sparePartTaxCategory);
    if (open && invoiceIn) {
      const foundInvoice = isDebitNote
        ? debitNotes.find((invoice) => invoice.invoiceNo === invoiceIn)
        : invoices.find((invoice) => invoice.invoiceNo === invoiceIn);

      if (!foundInvoice) {
        fetchFinalInvoice(invoiceIn);
      }
    }
    // eslint-disable-next-line
  }, [
    open,
    debitNotes,
    fetchDictionary,
    fetchFinalInvoice,
    invoiceIn,
    invoices,
    isDebitNote,
  ]);

  useEffect(() => {
    setOpen(triggerOpen);
  }, [triggerOpen]);

  useEffect(() => {
    if (!open) {
      setShownModal(ShownModal.NONE);
    }
    if (setOpenAddCorrectionModal) {
      setOpenAddCorrectionModal(open);
    }
  }, [open, setOpenAddCorrectionModal]);

  const getPercentageChange = (oldNumber: number, newNumber: number) => {
    const decreaseValue = oldNumber - newNumber;

    return +((decreaseValue / oldNumber) * 100).toFixed(2);
  };

  useEffect(() => {
    if (mappedFinalInvoice && finalInvoice && open) {
      // setSelectedInvoiceMask(finalInvoice.documentMask);
      // @ts-ignore: finalInvoiceLines any Type
      setLines(
        mappedFinalInvoice.finalInvoiceLines.map((line: any) => ({
          lineNumber: line.lineNumber,
          referenceId: line.referenceId,
          quantity: line.quantity,
          quantityOrgin: line.quantity,
          vat: line.taxRate,
          taxRate: line.taxRate,
          discount: line.discount,
          price: line.unitPriceNetBeforeDiscount
            ? line.unitPriceNetBeforeDiscount
            : 0,
          selected: false,
          unitPriceNetBeforeDiscount: line.unitPriceNetBeforeDiscount || 0,
          unitPriceNetAfterDiscount: line.unitPriceNetAfterDiscount || 0,
          previewDiscount: getPercentageChange(
            line.unitPriceNetBeforeDiscount,
            line.unitPriceNetAfterDiscount
          ),
          orderNumber: line.orderNumber,
        }))
      );
      setTableData({
        type: "INIT_TABLE",
        column: "lineNumber",
        data: mappedFinalInvoice.finalInvoiceLines,
      });
      // @ts-ignore: finalInvoiceLines any typ
      mappedFinalInvoice.finalInvoiceLines.map((item) => {
        item.previewDiscount = getPercentageChange(
          item.unitPriceNetBeforeDiscount,
          item.unitPriceNetAfterDiscount
        );
        return item;
      });
      setLines(
        mappedFinalInvoice.finalInvoiceLines.map((line: TLine) => ({
          correctionBlockade: mappedFinalInvoice.correctionBlockade,
          lineNumber: line.lineNumber,
          referenceId: line.referenceId,
          quantity: line.quantity,
          quantityOrgin: line.quantity,
          vat: line.taxRate,
          taxRate: line.taxRate,
          discount: line.discount,
          price: line.unitPriceNetBeforeDiscount
            ? line.unitPriceNetBeforeDiscount
            : 0,
          selected: false,
          unitPriceNetBeforeDiscount: line.unitPriceNetBeforeDiscount || 0,
          unitPriceNetAfterDiscount: line.unitPriceNetAfterDiscount || 0,
          previewDiscount: getPercentageChange(
            line.unitPriceNetBeforeDiscount,
            line.unitPriceNetAfterDiscount
          ),
          orderNumber: line.orderNumber,
          packageNumber: line.packageNumber,
        }))
      );
      setPreviousLines(
        mappedFinalInvoice.finalInvoiceLines.map((prevLine: TLine) => ({
          lineNumber: prevLine.lineNumber,
          quantity: prevLine.quantity,
          quantityOrgin: prevLine.quantity,
          discount: prevLine.discount,
          price: prevLine.unitPriceNetBeforeDiscount,
          unitPriceNetBeforeDiscount: prevLine.unitPriceNetBeforeDiscount,
          unitPriceNetAfterDiscount: prevLine.unitPriceNetAfterDiscount,
          vat: prevLine.taxRate,
          taxRate: prevLine.taxRate,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedFinalInvoice, finalInvoice, open]);

  const changeInvoice = (event: SyntheticEvent | null, data: any): void => {
    setInvoice(data.value);
    const foundInvoice = isDebitNote
      ? debitNotes.find((invoice) => invoice.invoiceNo === data.value)
      : invoices.find((invoice) => invoice.invoiceNo === data.value);
    if (foundInvoice) {
      fetchFinalInvoice(foundInvoice.invoiceNo);
    }
    setTableData({ type: "INIT_TABLE", column: "lineNumber", data: lines });
  };

  const getInvoices = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: 1,
        size: 13,
        filters: [],
        sortColumn: null,
        sortDirection: null,
      };
      fetchInvoices(Object.assign(paramsObj, params));
    },
    [fetchInvoices]
  );

  const getDebitNotes = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: 1,
        size: 13,
        filters: [],
        sortColumn: null,
        sortDirection: null,
      };
      fetchDebitNotes(Object.assign(paramsObj, params));
    },
    [fetchDebitNotes]
  );

  const searchInvoice = useCallback(
    (event: SyntheticEvent, data: any): void => {
      isDebitNote
        ? getDebitNotes({
            filters: [{ name: "documentMask", key: data.searchQuery }],
          })
        : getInvoices({
            filters: [{ name: "documentMask", key: data.searchQuery }],
          });
      setTableData({ type: "INIT_TABLE", column: "lineNumber", data: lines });
    },
    [getDebitNotes, getInvoices, isDebitNote, lines]
  );

  const inputDescription = (event: SyntheticEvent, data: any): void => {
    setDescription(data.value);
  };

  const mapInvoices = (invoices: IInvoice[]): DictionaryItem[] => {
    return isDebitNote
      ? debitNotes.map((invoice) => ({
          key: invoice.invoiceNo,
          text: invoice.documentMask,
          value: invoice.invoiceNo,
        }))
      : invoices.map((invoice) => ({
          key: invoice.invoiceNo,
          text: invoice.documentMask,
          value: invoice.invoiceNo,
        }));
  };

  const mapVat = (vatDicts: DictionaryItem[]): DictionaryItem[] => {
    return vatDicts.map((vat) => ({
      key: vat.key,
      text: vat.text,
      value: vat.valueOrgin,
    }));
  };

  const clearForm = (): void => {
    setChanged(false);
    setInvoice("");
    setLines([]);
    setPreviousLines([]);
    setTableData({ type: "INIT_TABLE", column: null, data: null });
    if (claimNumber) {
      setDescription(`${claimNumber} - `);
    } else {
      setDescription("");
    }
  };

  const compareLines = (comparedLines: TLine[]) => {
    return JSON.stringify(
      comparedLines.map((line) => ({
        discount: line.discount,
        quantity: line.quantity,
        unitPriceNetAfterDiscount: line.unitPriceNetAfterDiscount,
        unitPriceNetBeforeDiscount: line.unitPriceNetBeforeDiscount,
        vat: line.vat,
      }))
    );
  };

  // const handleCreateRestCorrection = (): void => {
  //   setIsResetCorrection(true);
  //   createInvoiceCorrection(
  //     invoice,
  //     {
  //       correctionReason: description,
  //       correctedInvoiceLines: previousLines.map((line) => ({
  //         numberLine: Number(line.lineNumber),
  //         correctedQuantity: Number(0),
  //         correctedTaxRate: Number(line.vat),
  //         unitPriceNetBeforeDiscount: Number(line.unitPriceNetBeforeDiscount),
  //         unitPriceNetAfterDiscount: Number(line.unitPriceNetAfterDiscount),
  //       })),
  //     },
  //     claimNumber
  //   );
  // };

  const handleConfirm = (): void => {
    setIsResetCorrection(false);

    const linesCompared = compareLines(lines);
    // @ts-ignore: missing properties
    const previousLinesCompared = compareLines(previousLines);

    if (linesCompared === previousLinesCompared) {
      toastWarn(
        "Nie można utworzyć korekty",
        "Korekta nie wprowadza żadnych zmian."
      );
      return;
    }
    createInvoiceCorrection(
      invoiceIn || "",
      {
        correctionReason: description,
        correctedInvoiceLines: lines
          .filter((line) => line.selected)
          .filter((line) => {
            const prevLine = previousLines.find(
              (previousLIne) => previousLIne.lineNumber === line.lineNumber
            );
            return !(
              prevLine?.discount === line.discount &&
              prevLine.price === line.price &&
              prevLine.quantity === line.quantity &&
              prevLine.unitPriceNetBeforeDiscount ===
                line.unitPriceNetBeforeDiscount &&
              prevLine.unitPriceNetAfterDiscount ===
                line.unitPriceNetAfterDiscount &&
              prevLine.vat === line.vat
            );
          })
          .map((line) => {
            return {
              numberLine: Number(line.lineNumber),
              correctedQuantity: Number(line.quantity),
              correctedTaxRate: Number(line.vat),
              unitPriceNetBeforeDiscount: Number(
                line.unitPriceNetBeforeDiscount
              ),
              unitPriceNetAfterDiscount: Number(line.unitPriceNetAfterDiscount),
            };
          }),
      },
      claimNumber
    );
  };

  const handleCancel = (): void => {
    clearForm();
    clearFinalInvoice();
    if (!creating) {
      setOpen(false);
    }
    if (shownModal === ShownModal.NONE) return;
  };

  const handleOpen = (): void => {
    clearForm();
    setOpen(true);
  };

  if (created && open) {
    clearCreated();
    clearForm();
    setMappedFinalInvoice(undefined);
    setCorrectQuantities(undefined);
    setOpen(false);
    createdSuccess();
  }

  const selectAll = (event: SyntheticEvent, data: any): void => {
    setLines(lines.map((line) => ({ ...line, selected: data.checked })));
    setTableData({
      type: "SELECT_ALL",
      column: "lineNumber",
      data: lines,
      selected: data.checked,
    });
  };

  const selectLine = (
    lineNumber: number,
    event: SyntheticEvent,
    data: any
  ): void => {
    setLines(
      lines.map((line) => {
        if (line.lineNumber === lineNumber) {
          setTableData({
            type: "SELECT",
            column: "lineNumber",
            data: lines,
            checkedLine: lineNumber,
            selected: data.checked,
          });
          return { ...line, selected: data.checked };
        }
        return { ...line };
      })
    );
  };

  const handleSearchChange = React.useCallback(
    (e, data) => {
      // @ts-ignore-next-line
      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          setTableData({ type: "INIT_TABLE", data: lines });
          return;
        }
      }, 300);
    },
    [lines]
  );

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <AddInvoiceCorrectionContent
      changeInvoice={changeInvoice}
      children={children}
      description={description}
      getPercentageChange={getPercentageChange}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      handleCreateRestCorrection={() => {}}
      handleOpen={handleOpen}
      handleSearchChange={handleSearchChange}
      inputDescription={inputDescription}
      invoice={invoice}
      invoiceIn={invoiceIn}
      invoiceMask={selectedInvoiceMask}
      isDebitNote={isDebitNote}
      isResetCorrection={isResetCorrection}
      lines={lines}
      mapInvoices={mapInvoices}
      mapVat={mapVat}
      open={open}
      previousLines={previousLines}
      searchInvoice={searchInvoice}
      setLines={setLines}
      selectAll={selectAll}
      selectLine={selectLine}
      setTableData={setTableData}
      setShownModal={setShownModal}
      shownModal={shownModal}
      tableData={tableData}
      changed={changed}
      setChanged={setChanged}
      finalInvoice={mappedFinalInvoice}
    />
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  invoices,
  dictionaries,
}: ApplicationState) => ({
  invoices: invoices.list,
  debitNotes: invoices.debitNotes.list,
  loadingDictionaries: dictionaries.loading,
  loadingInvoices: invoices.loadingInvoices,
  created: invoices.created,
  creating: invoices.creating,
  finalInvoice: invoices.selectedFinalInvoice,
  dictionaries,
});

const mapDispatchToProps: TReduxActions = {
  createInvoiceCorrection,
  clearCreated,
  fetchInvoices,
  fetchDictionary,
  fetchFinalInvoice,
  clearFinalInvoice,
  fetchDebitNotes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInvoiceCorrection);
