import { SyntheticEvent, useCallback, useState } from "react";
import { CLAIM_STATUS_ENUM, TClaimLine } from "~/store/claims/types";
import { FormCategory } from "../Transition/transitionColumns.conf";
import { CostCoveringGroup } from "~/pages/Claims/lib/claimTypes";

type TProps = {
  transitionLineIndex: number | null;
  transitionLines: TClaimLine[];
  setTransitionLines: React.Dispatch<React.SetStateAction<TClaimLine[]>>;
  claimLines: TClaimLine[];
  claimStatus: CLAIM_STATUS_ENUM;
};

export const useChangeLine = ({
  transitionLineIndex,
  transitionLines,
  claimLines,
  setTransitionLines,
  claimStatus,
}: TProps) => {
  const [costGroup, setCostGroup] = useState<CostCoveringGroup | "">("");
  const isValidQuantityBOK = (line: TClaimLine) => {
    return (
      line.claimedQuantity -
        (line.acceptedQuantity || 0) -
        (line.rejectQuantity || 0) -
        (line.requestReturnQuantity || 0) ===
      0
    );
  };

  const changeLine = useCallback(
    (
      event: SyntheticEvent,
      data: any,
      type: string,
      formCategory?: string,
      onlyReturning?: boolean
    ): void => {
      if (transitionLineIndex === null) return;

      const isValueType = type !== "statusNotes";
      const value = isValueType ? parseInt(data.value) : data.value;

      if (isValueType && value < 0) return;

      let newLines = [...transitionLines];

      if (
        formCategory === FormCategory.RECEIVED_FULL_VALUE ||
        formCategory === FormCategory.RECEIVED_DEFECTIVE
      ) {
        let {
          claimedQuantity,
          acceptedQuantity,
          decisionAcceptedWithoutCorrectionQuantity,
          decisionToDestroyQuantity,
          decisionToDestroyWithoutCorrectionQuantity,
          decisionRejectedToClientQuantity,
        } = transitionLines[transitionLineIndex][`${formCategory}`];

        if (
          type === "acceptedQuantity" &&
          value >
            claimedQuantity -
              decisionAcceptedWithoutCorrectionQuantity -
              decisionToDestroyQuantity -
              decisionToDestroyWithoutCorrectionQuantity -
              decisionRejectedToClientQuantity
        )
          return;

        if (
          type === "decisionAcceptedWithoutCorrectionQuantity" &&
          value >
            claimedQuantity -
              acceptedQuantity -
              decisionToDestroyQuantity -
              decisionToDestroyWithoutCorrectionQuantity -
              decisionRejectedToClientQuantity
        )
          return;
        if (
          type === "decisionToDestroyQuantity" &&
          value >
            claimedQuantity -
              acceptedQuantity -
              decisionAcceptedWithoutCorrectionQuantity -
              decisionToDestroyWithoutCorrectionQuantity -
              decisionRejectedToClientQuantity
        )
          return;
        if (
          type === "decisionToDestroyWithoutCorrectionQuantity" &&
          value >
            claimedQuantity -
              acceptedQuantity -
              decisionAcceptedWithoutCorrectionQuantity -
              decisionToDestroyQuantity -
              decisionRejectedToClientQuantity
        )
          return;
        if (
          type === "decisionRejectedToClientQuantity" &&
          value >
            claimedQuantity -
              acceptedQuantity -
              decisionAcceptedWithoutCorrectionQuantity -
              decisionToDestroyQuantity -
              decisionToDestroyWithoutCorrectionQuantity
        )
          return;

        let formValue: any = {
          ...transitionLines[transitionLineIndex][`${formCategory}`],
          [`${type}`]: value,
        };

        const isValidQuantity =
          claimedQuantity -
            formValue.acceptedQuantity -
            formValue.decisionAcceptedWithoutCorrectionQuantity -
            formValue.decisionToDestroyQuantity -
            formValue.decisionToDestroyWithoutCorrectionQuantity -
            formValue.decisionRejectedToClientQuantity ===
          0;
        formValue = {
          ...formValue,
          isNotValidQuantity:
            isValidQuantity !== true
              ? `Suma musi byc równa ${claimedQuantity}`
              : false,
        };

        newLines[transitionLineIndex] = {
          ...transitionLines[transitionLineIndex],
          [`${formCategory}`]: formValue,
        };

        setTransitionLines(newLines);
      } else {
        let {
          claimedQuantity,
          requestReturnQuantity,
          rejectQuantity,
          acceptedQuantity,
        } = transitionLines[transitionLineIndex];
        if (typeof value === "number" && value > claimedQuantity) {
          return;
        } else if (type === "acceptedQuantity") {
          if (
            acceptedQuantity === claimedQuantity &&
            value >= claimedQuantity
          ) {
            return;
          }
          // DECREASING - move from accpeted to rejected
          if (value < acceptedQuantity) {
            newLines[transitionLineIndex] = {
              ...transitionLines[transitionLineIndex],
              acceptedQuantity: value,
              rejectQuantity: claimedQuantity - requestReturnQuantity - value,
            };
            // INCREASING - takes first from rejected, then from returned
          } else if (acceptedQuantity >= 0 && value > acceptedQuantity) {
            const incrementBy = value - acceptedQuantity;
            if (rejectQuantity - incrementBy < 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                acceptedQuantity: value,
                rejectQuantity: 0,
                requestReturnQuantity: claimedQuantity - value,
              };
            } else if (rejectQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                rejectQuantity: claimedQuantity - requestReturnQuantity - value,
                acceptedQuantity: value,
              };
            } else if (rejectQuantity === 0 && requestReturnQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                requestReturnQuantity:
                  claimedQuantity - requestReturnQuantity - value,
                acceptedQuantity: value,
              };
            }
          }
        } else if (type === "requestReturnQuantity") {
          if (
            requestReturnQuantity === claimedQuantity &&
            value >= claimedQuantity
          )
            return;
          // DECREASING - move from accpeted to rejected
          if (value < requestReturnQuantity) {
            if (!onlyReturning) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                requestReturnQuantity: value,
                rejectQuantity: claimedQuantity - acceptedQuantity - value,
              };
            } else {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                requestReturnQuantity: value,
              };
            }
            // INCREASING - takes first from rejected, then from returned
          } else if (
            requestReturnQuantity >= 0 &&
            value > requestReturnQuantity
          ) {
            if (
              acceptedQuantity + rejectQuantity === claimedQuantity &&
              requestReturnQuantity === claimedQuantity
            )
              return;
            if (onlyReturning) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                requestReturnQuantity: value,
              };
            }
            const incrementBy = value - requestReturnQuantity;
            if (!onlyReturning && rejectQuantity - incrementBy < 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                requestReturnQuantity: value,
                rejectQuantity: 0,
                acceptedQuantity: claimedQuantity - value,
              };
            } else if (!onlyReturning && rejectQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                rejectQuantity: claimedQuantity - acceptedQuantity - value,
                requestReturnQuantity: value,
              };
            } else if (
              !onlyReturning &&
              rejectQuantity === 0 &&
              acceptedQuantity > 0
            ) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                acceptedQuantity: claimedQuantity - acceptedQuantity - value,
                requestReturnQuantity: value,
              };
            }
          }
        } else if (type === "rejectQuantity") {
          if (rejectQuantity === claimedQuantity && value >= claimedQuantity)
            return;
          // DECREASING - move from accpeted to rejected
          if (value < rejectQuantity) {
            newLines[transitionLineIndex] = {
              ...transitionLines[transitionLineIndex],
              rejectQuantity: value,
              acceptedQuantity: claimedQuantity - requestReturnQuantity - value,
            };
            // INCREASING - takes first from rejected, then from returned
          } else if (rejectQuantity >= 0 && value > rejectQuantity) {
            if (requestReturnQuantity === 0 && acceptedQuantity === 0) return;
            const incrementBy = value - rejectQuantity;
            if (acceptedQuantity - incrementBy < 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                rejectQuantity: value,
                acceptedQuantity: 0,
                requestReturnQuantity: claimedQuantity - value,
              };
            } else if (acceptedQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                acceptedQuantity:
                  claimedQuantity - requestReturnQuantity - value,
                rejectQuantity: value,
              };
            } else if (acceptedQuantity === 0 && requestReturnQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...transitionLines[transitionLineIndex],
                requestReturnQuantity:
                  claimedQuantity - requestReturnQuantity - value,
                rejectQuantity: value,
              };
            }
          }
        } else {
          newLines[transitionLineIndex] = {
            ...transitionLines[transitionLineIndex],
            [`${type}`]: value,
          };
        }

        const isValidQuantity = isValidQuantityBOK(
          newLines[transitionLineIndex]
        );
        // claimedQuantity -
        //   (newLines[transitionLineIndex].acceptedQuantity || 0) -
        //   (newLines[transitionLineIndex].rejectQuantity || 0) -
        //   (newLines[transitionLineIndex].requestReturnQuantity || 0) ===
        // 0;

        newLines[transitionLineIndex] = {
          ...newLines[transitionLineIndex],
          isNotValidQuantity:
            isValidQuantity !== true
              ? `Suma musi byc równa ${claimedQuantity}`
              : false,
        };

        setTransitionLines(newLines);
      }
      // eslint-disable-next-line
    },
    [transitionLines, transitionLineIndex, setTransitionLines]
  );

  const isValidQuantity = (line: TClaimLine) => {
    return (
      (line.acceptedQuantityByTransport || 0) +
        (line.acceptedQuantityByWarehouse || 0) +
        (line.acceptedQuantityByDistrigo || 0) +
        (line.rejectQuantity || 0) ===
      line.claimedQuantity
    );
  };

  const isValidQuantityActors = (line: TClaimLine) => {
    return (
      (line.acceptedQuantityByDistrigo || 0) +
        (line.acceptedQuantityByTransport || 0) +
        (line.acceptedQuantityByWarehouse || 0) +
        line.rejectQuantity ===
      line.claimedQuantity
    );
  };

  const orZero = (value: number | null | undefined): number => {
    if (!value) {
      return 0;
    } else {
      return value;
    }
  };

  const changeLineCostGroup = useCallback(
    (
      event: SyntheticEvent,
      data: any,
      type: string,
      formCategory?: string,
      onlyReturning?: boolean,
      coverBy?: CostCoveringGroup
    ): void => {
      setTransitionLines((linesState) => {
        if (transitionLineIndex === null) return linesState;

        const isValueType = type !== "statusNotes";
        const value = isValueType ? parseInt(data.value) : data.value;

        if (isValueType && value < 0) return linesState;

        let newLines = [...linesState];

        if (type === "costGroupChange") {
          setCostGroup(data.value);
          newLines[transitionLineIndex] = {
            ...claimLines[transitionLineIndex],
            selectedCostGroup: data.value,
            requestReturnQuantity: 0,
            rejectQuantity: 0,
            acceptedQuantityByWarehouse: orZero(
              claimLines[transitionLineIndex].acceptedQuantityByWarehouse
            ),
            acceptedQuantityByTransport: orZero(
              claimLines[transitionLineIndex].acceptedQuantityByTransport
            ),
          };
        }

        let {
          claimedQuantity,
          rejectQuantity,
          acceptedQuantityByWarehouse,
          acceptedQuantityByTransport,
          acceptedQuantityByDistrigo,
        } = linesState[transitionLineIndex];
        if (typeof value === "number" && value > claimedQuantity) {
          // return when setted quantity is greater than claimed
          return linesState;
        } else if (
          (type === "acceptedQuantityByWarehouse"
            ? value
            : acceptedQuantityByWarehouse) +
            (type === "acceptedQuantityByTransport"
              ? value
              : acceptedQuantityByTransport) +
            (type === "rejectQuantity" ? value : rejectQuantity) +
            acceptedQuantityByDistrigo >
          claimedQuantity
        ) {
          // return when quantity of all fields is greater than claimed
          return linesState;
        } else if (type === "acceptedQuantityByWarehouse") {
          // DECREASING - move from accpeted to rejected
          if (value < acceptedQuantityByWarehouse) {
            newLines[transitionLineIndex] = {
              ...linesState[transitionLineIndex],
              acceptedQuantityByWarehouse: value,
            };
          } else if (
            acceptedQuantityByWarehouse >= 0 &&
            value > acceptedQuantityByWarehouse
          ) {
            const incrementBy = value - acceptedQuantityByWarehouse;
            if (rejectQuantity - incrementBy < 0) {
              newLines[transitionLineIndex] = {
                ...linesState[transitionLineIndex],
                acceptedQuantityByWarehouse: value,
              };
            } else if (rejectQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...linesState[transitionLineIndex],
                acceptedQuantityByWarehouse: value,
              };
            } else if (rejectQuantity === 0) {
              newLines[transitionLineIndex] = {
                ...linesState[transitionLineIndex],
                acceptedQuantityByWarehouse: value,
              };
            }
          }
        } else if (type === "acceptedQuantityByTransport") {
          // DECREASING - move from accpeted to rejected
          if (value < acceptedQuantityByTransport) {
            newLines[transitionLineIndex] = {
              ...linesState[transitionLineIndex],
              acceptedQuantityByTransport: value,
            };
            // INCREASING - takes first from rejected, then from returned
          } else if (
            acceptedQuantityByTransport >= 0 &&
            value > acceptedQuantityByTransport
          ) {
            const incrementBy = value - acceptedQuantityByTransport;
            if (rejectQuantity - incrementBy < 0) {
              newLines[transitionLineIndex] = {
                ...linesState[transitionLineIndex],
                acceptedQuantityByTransport: value,
              };
            } else if (rejectQuantity > 0) {
              newLines[transitionLineIndex] = {
                ...linesState[transitionLineIndex],
                acceptedQuantityByTransport: value,
              };
            } else if (rejectQuantity === 0) {
              newLines[transitionLineIndex] = {
                ...linesState[transitionLineIndex],
                acceptedQuantityByTransport: value,
              };
            }
          }
        } else if (type === "rejectQuantity") {
          if (rejectQuantity === claimedQuantity && value >= claimedQuantity)
            return linesState;
          // DECREASING - move from accpeted to rejected
          if (value < rejectQuantity) {
            if (coverBy === CostCoveringGroup.transport) {
              newLines[transitionLineIndex] = {
                ...linesState[transitionLineIndex],
                rejectQuantity: value,
              };
            }

            if (coverBy === CostCoveringGroup.warehouse) {
              newLines[transitionLineIndex] = {
                ...linesState[transitionLineIndex],
                rejectQuantity: value,
              };
            }

            // INCREASING - takes first from rejected, then from returned
          } else if (rejectQuantity >= 0 && value > rejectQuantity) {
            const incrementBy = value - rejectQuantity;
            if (acceptedQuantityByWarehouse - incrementBy < 0) {
              newLines[transitionLineIndex] = {
                ...linesState[transitionLineIndex],
                rejectQuantity: value,
              };
            } else if (acceptedQuantityByWarehouse > 0) {
              newLines[transitionLineIndex] = {
                ...linesState[transitionLineIndex],
                rejectQuantity: value,
              };
            } else if (acceptedQuantityByWarehouse === 0) {
              newLines[transitionLineIndex] = {
                ...linesState[transitionLineIndex],
                rejectQuantity: value,
              };
            }
          }
        } else if (type === "reset") {
          newLines[transitionLineIndex] = {
            ...claimLines[transitionLineIndex],
            requestReturnQuantity: 0,
            rejectQuantity: 0,
            acceptedQuantityByWarehouse:
              claimLines[transitionLineIndex].acceptedQuantityByWarehouse,
            acceptedQuantityByTransport:
              claimLines[transitionLineIndex].acceptedQuantityByTransport,
          };
        }

        newLines[transitionLineIndex] = {
          ...newLines[transitionLineIndex],
          isNotValidQuantity:
            claimStatus === CLAIM_STATUS_ENUM.VERIFICATION
              ? !isValidQuantityActors(newLines[transitionLineIndex])
              : !isValidQuantity(newLines[transitionLineIndex]),
        };
        return newLines;
      });
    },
    [transitionLineIndex, setTransitionLines, claimLines, claimStatus]
  );

  return {
    changeLine,
    changeLineCostGroup,
    isValidQuantity,
    isValidQuantityBOK,
    orZero,
    costGroup,
  };
};
