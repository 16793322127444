import React, { useMemo } from "react";
import { Form, Table } from "semantic-ui-react";
import DetailCard from "~/components/DetailCard/DetailCard";
import { actionTypes, editFormColumns } from "./transitionColumns.conf";
import { useClaimsRolesRights } from "~/pages/Claims/hooks/useClaimsRights";
import {
  ClaimCategory,
  CostCoveringGroup,
} from "~/pages/Claims/lib/claimTypes";
import dictionariesStatic from "~/store/dictionaries/static";
import { TClaimLine } from "~/store/claims/types";

type TProps = {
  transitionLineIndex: number | null;
  transitionLines: TClaimLine[];
  changeLine: (
    event: React.SyntheticEvent,
    data: any,
    type: string,
    formCategory?: string,
    onlyReturning?: boolean,
    coverBy?: CostCoveringGroup
  ) => void;
  claimCategory: ClaimCategory;
  claimGroup: string;
  chatTargetGroups: string[];
};

const ActorsEditLine = ({
  transitionLineIndex,
  transitionLines,
  changeLine,
  claimCategory,
  claimGroup,
  chatTargetGroups,
}: TProps) => {
  const {
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
  } = useClaimsRolesRights();

  const costGroupOptions = useMemo(() => {
    let groups: {
      value: string;
      text: string;
    }[] = [];
    const dictionary = [...dictionariesStatic.COST_COVERING_GROUP].map(
      (item) => ({ value: item.value, text: item.text })
    );
    if (userHaveLdcUiClaimsProcessRole) {
      groups.push(
        dictionary.find((item) => item.value === CostCoveringGroup.distrigo)!
      );
    }
    if (
      userHaveLdcUiWarehouseClaimsProcessRole &&
      !!chatTargetGroups.find((item) => item === "WAREHOUSE")
    ) {
      groups.push(
        dictionary.find((item) => item.value === CostCoveringGroup.warehouse)!
      );
    }
    if (
      userHaveLdcUiTransportClaimsProcessRole &&
      !!chatTargetGroups.find((item) => item === "TRANSPORT")
    ) {
      groups.push(
        dictionary.find((item) => item.value === CostCoveringGroup.transport)!
      );
    }
    return groups;
  }, [
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
    chatTargetGroups,
  ]);

  if (transitionLineIndex === null) return null;

  const {
    referenceName,
    referenceId,
    rejectQuantity,
    acceptedQuantity,
    isNotValidQuantity,
    claimReturnedPart,
    acceptedQuantityByWarehouse,
    acceptedQuantityByTransport,
    selectedCostGroup,
  } = transitionLines[transitionLineIndex];

  const getCardTitle = () => {
    return claimReturnedPart?.id && claimReturnedPart?.name
      ? `${claimReturnedPart.id} (${claimReturnedPart.name})`
      : `${referenceName} (${referenceId})`;
  };

  return (
    <>
      <DetailCard
        title={getCardTitle()}
        id="status"
        key="status"
        titleFormat={true}
        minHeight={100}
        width={16}
      >
        <Table>
          <Table.Header>
            <Table.Row>
              {editFormColumns.map((column) => (
                <Table.HeaderCell
                  key={column.name}
                  style={{ width: column.width }}
                >
                  {!selectedCostGroup ||
                  (selectedCostGroup === CostCoveringGroup.distrigo &&
                    column.name === "costCoveringGroup")
                    ? ""
                    : column.label}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {actionTypes["VERIFICATION_ACCEPTED_T_M"]}
              </Table.Cell>
              <Table.Cell>
                <Form.Select
                  options={costGroupOptions}
                  value={selectedCostGroup}
                  onChange={(e, data) => {
                    changeLine(e, data, "costGroupChange");
                  }}
                  width={8}
                  className="cost-group-change"
                ></Form.Select>
              </Table.Cell>
              <Table.Cell>
                {selectedCostGroup === CostCoveringGroup.distrigo ||
                !selectedCostGroup ? (
                  <Form.Input
                    type="number"
                    min="0"
                    error={isNotValidQuantity}
                    value={acceptedQuantity}
                    onChange={(e, d) => changeLine(e, d, "acceptedQuantity")}
                  />
                ) : null}
                {selectedCostGroup === CostCoveringGroup.warehouse ? (
                  <Form.Input
                    type="number"
                    min="0"
                    error={isNotValidQuantity}
                    value={acceptedQuantityByWarehouse}
                    onChange={(e, d) =>
                      changeLine(
                        e,
                        d,
                        "acceptedQuantityByWarehouse",
                        undefined,
                        undefined,
                        CostCoveringGroup.warehouse
                      )
                    }
                  />
                ) : null}
                {selectedCostGroup === CostCoveringGroup.transport ? (
                  <Form.Input
                    type="number"
                    min="0"
                    error={isNotValidQuantity}
                    value={acceptedQuantityByTransport}
                    onChange={(e, d) =>
                      changeLine(
                        e,
                        d,
                        "acceptedQuantityByTransport",
                        undefined,
                        undefined,
                        CostCoveringGroup.warehouse
                      )
                    }
                  />
                ) : null}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{actionTypes["VERIFICATION_REJECTED"]}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <Form.Input
                  type="number"
                  min="0"
                  error={isNotValidQuantity}
                  value={rejectQuantity}
                  onChange={(e, d) =>
                    changeLine(
                      e,
                      d,
                      "rejectQuantity",
                      undefined,
                      undefined,
                      selectedCostGroup
                    )
                  }
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </DetailCard>
    </>
  );
};

export default ActorsEditLine;
