import React, { useCallback, useMemo } from "react";
import { Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import dictionariesStatic from "~/store/dictionaries/static";
import { DictionaryName } from "~/store/dictionaries/types";

type TProps = {
  userHaveLdcUiTransportClaimsProcessRole: boolean;
  userHaveLdcUiWarehouseClaimsProcessRole: boolean;
  userHaveLdcUiClaimsProcessRole: boolean;
};

export const useClaimsColumns = ({
  userHaveLdcUiClaimsProcessRole,
  userHaveLdcUiTransportClaimsProcessRole,
  userHaveLdcUiWarehouseClaimsProcessRole,
}: TProps) => {
  const getSupplierSystemCell = useCallback(
    (claim: any, column: any, fieldName: string): JSX.Element => {
      return (
        <Table.Cell key={column.name}>
          {claim.supplierSystem ? claim.supplierSystem[0][fieldName] : ""}
        </Table.Cell>
      );
    },
    []
  );

  const {
    getAmountCell,
    getDateCell,
    getLinkCell,
    getTextCell,
    getDictionaryCell,
  } = useCellsRenderer();

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "claimNumber",
        label: "Numer",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "createDate",
        label: "Utworzone",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "status",
        dictionaryName: DictionaryName.claimStatus,
        label: "Status",
        projection: true,
        noKey: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "statusDate",
        label: "Aktualizacja",
        projection: true,
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "invoiceDocumentMask",
        label: "Faktura",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "orderNumber",
        label: "Zamówienie",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },

      {
        name: "supplierSystem.businessSector",
        label: "Dostawca",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getSupplierSystemCell(document, column, "businessSector"),
      },
      {
        name: "supplierSystem.clientId",
        label: "Sincom",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getSupplierSystemCell(document, column, "clientId"),
      },
      {
        name: "supplierSystem.deliveryId",
        label: "Punkt dostawy",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getSupplierSystemCell(document, column, "deliveryId"),
      },
      {
        name: "claimGroup",
        dictionaryName: DictionaryName.claimGroup,
        label: "Rodzaj",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "claimCategory",
        dictionaryName: DictionaryName.claimCategory,
        label: "Kategoria",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "readStatus",
        dictionary: dictionariesStatic.BOOLEAN_VALUES,
        label: "Przeczytane",
        projection:
          userHaveLdcUiClaimsProcessRole ||
          userHaveLdcUiTransportClaimsProcessRole ||
          userHaveLdcUiWarehouseClaimsProcessRole,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
        hide: !(
          userHaveLdcUiClaimsProcessRole ||
          userHaveLdcUiTransportClaimsProcessRole ||
          userHaveLdcUiWarehouseClaimsProcessRole
        ),
      },
      {
        name: "claimType",
        dictionaryName: DictionaryName.claimType,
        label: "Typ",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "orderCustomerID",
        label: "Klient",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.orderCustomerID}`
          ),
      },
      {
        name: "accountingCustomerID",
        label: "Płatnik",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.accountingCustomerID}`
          ),
      },
      {
        name: "orderCustomerDdsID",
        label: "OV odbiorca",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "warehouseStatusUpdatedAt",
        label: "Aktualizacja magazynu",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "warehouseStatus",
        label: "Status magazynu",
        dictionaryName: DictionaryName.claimWarehouseStatus,
        projection: true,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "transportStatusUpdatedAt",
        label: "Aktualizacja transportu",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "transportStatus",
        label: "Status transportu",
        dictionaryName: DictionaryName.claimTransportStatus,
        projection: true,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "backInTheBox",
        label: "Numer RPL",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "claimedValue",
        label: "Wartość reklamacji",
        projection: false,
        priceColumn: true,
        filteringBlocked: true,
        disableSort: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "value",
        label: "Wartość zaakceptowana",
        projection: false,
        priceColumn: true,
        filteringBlocked: true,
        disableSort: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "containsCspsParts",
        dictionary: dictionariesStatic.BOOLEAN_VALUES,
        label: "Zawiera CSPS",
        projection: true,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "containsPlprParts",
        dictionary: dictionariesStatic.BOOLEAN_VALUES,
        label: "Zawiera PLPR",
        projection: true,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "containsDfsParts",
        dictionary: dictionariesStatic.BOOLEAN_VALUES,
        label: "Zawiera DFS",
        projection: true,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
    ],
    [
      getAmountCell,
      getDateCell,
      getLinkCell,
      getSupplierSystemCell,
      getTextCell,
      getDictionaryCell,
      userHaveLdcUiClaimsProcessRole,
      userHaveLdcUiTransportClaimsProcessRole,
      userHaveLdcUiWarehouseClaimsProcessRole,
    ]
  );
  return { columnsConfig };
};
