import React, {
  Fragment,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Header,
  InputOnChangeData,
  Modal,
  Popup,
  Search,
  Table,
} from "semantic-ui-react";

import { FormInputNumber } from "~/components/InputNumber/InputNumber";
import ModalConfirm from "~/components/Modals/ModalConfirm";
import { ApplicationState } from "~/store";
import { DictionariesState, DictionaryItem } from "~/store/dictionaries/types";
import { TLine } from "~/pages/Accounting/lib/AddInvoiceCorrection/AddInvoiceCorrection";
import { IInvoice, TInvoicePreviousLine } from "~/store/invoices/types";
import TablePaginationFooter from "~/components/TablePaginationFooter/TablePaginationFooter";
import { TotalRecords } from "~/store/types";
import { SearchProps } from "semantic-ui-react/dist/commonjs/modules/Search/Search";
import { toastWarn } from "~/utils/toast";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";

const _ = require("lodash");

enum ShownModal {
  NONE,
  CONFIRM_CORRECTION,
  CONFIRM_RESET_CORRECTION,
}

type AddInvoiceCorrectionReferenceLineProps = {
  changeAfterDiscount: (
    lineNumber: number,
    event: SyntheticEvent,
    data: any
  ) => void;
  changeBeforeDiscount: (
    lineNumber: number,
    event: SyntheticEvent,
    data: any
  ) => void;
  changeQuantity: (
    lineNumber: number,
    event: SyntheticEvent,
    data: any
  ) => void;
  changed?: boolean;
  changeInvoice: (event: SyntheticEvent | null, data: any) => void;
  changeVat: (lineNumber: number, event: SyntheticEvent, data: any) => void;
  children: React.ReactNode;
  description: string;
  getPercentageChange: (
    oldNumber: number,
    newNumber: number
  ) => number | undefined;
  handleCancel: () => void;
  handleConfirm: () => void;
  handleCreateRestCorrection: () => void;
  handleOpen: () => void;
  handleSearchChange: (e: SyntheticEvent, data: any) => void;
  initLineValue: (lineNo: number, key: string) => string | undefined;
  inputDescription: (event: SyntheticEvent, data: any) => void;
  invoice: string;
  invoiceIn?: string;
  invoiceMask?: string;
  isChecked: (line: TLine) => boolean | undefined;
  isInvalidQuantity: (line: TLine, lineNumber: number) => boolean | void;
  isInvalidUnitPriceNetAfterDiscount: (
    line: TLine,
    lineNumber: number
  ) => boolean | void;
  isPriceBeforeDiscountInvalid: (lineNumber: number) => boolean;
  isInvalidVat: (line: TLine, lineNumber: number) => boolean | undefined;
  isNotValidFormForInvoiceReset: () => boolean;
  isValidFormForCreate: () => boolean;
  validLines: (lineNumber: number) => boolean | void;
  isDebitNote?: boolean;
  isResetCorrection?: boolean;
  mapInvoices: (invoices: IInvoice[]) => DictionaryItem[];
  mapVat: (vatDicts: DictionaryItem[]) => DictionaryItem[];
  open: boolean;
  previousLines: TInvoicePreviousLine[];
  searchInvoice: (event: SyntheticEvent<Element, Event>, data: any) => void;
  selectLine: (lineNumber: number, event: SyntheticEvent, data: any) => void;
  setTableData: (key: {
    type?: string | undefined;
    column?: string | undefined;
  }) => void;
  setShownModal: (key: ShownModal) => void;
  shownModal: ShownModal;
  tableData: {
    data: TLine[];
    direction: any;
    loading: boolean;
    checkedLine: boolean;
    selected: boolean;
    column: string;
  };
  setChanged: React.Dispatch<React.SetStateAction<boolean>>;
  finalInvoice?: any;
};

type TReduxState = {
  invoices: IInvoice[];
  loadingInvoices: boolean;
  loadingDictionaries: boolean;
  creating?: boolean;
  dictionaries: DictionariesState;
  // finalInvoice?: IInvoice;
  debitNotes: IInvoice[];
  quantityRedux?: number;
};

type TFilteredLine = TLine & { currIndex: number };

type TFilteredTableData = {
  data: TFilteredLine[];
  direction: any;
  loading: boolean;
  checkedLine: boolean;
  selected: boolean;
  column: string;
};

type TAddInvoiceCorrectionReferenceLine =
  AddInvoiceCorrectionReferenceLineProps & TReduxState;

const AddInvoiceCorrectionReferenceLine = ({
  changeAfterDiscount,
  changeBeforeDiscount,
  changeQuantity,
  isChecked,
  isInvalidQuantity,
  isInvalidUnitPriceNetAfterDiscount,
  isPriceBeforeDiscountInvalid,
  isInvalidVat,
  isValidFormForCreate,
  changeInvoice,
  changeVat,
  changed,
  children,
  description,
  handleCancel,
  handleConfirm,
  handleCreateRestCorrection,
  handleOpen,
  handleSearchChange,
  initLineValue,
  isDebitNote,
  invoice,
  invoices,
  isResetCorrection,
  loadingInvoices,
  loadingDictionaries,
  creating,
  inputDescription,
  invoiceIn,
  invoiceMask,
  dictionaries,
  debitNotes,
  finalInvoice,
  mapInvoices,
  mapVat,
  open,
  searchInvoice,
  selectLine,
  setTableData,
  setShownModal,
  shownModal,
  tableData,
  setChanged,
  validLines,
}: TAddInvoiceCorrectionReferenceLine) => {
  const context = useContext(AppContext);
  const userHaveLdcUiAccountingCorrectionPositive = () =>
    context?.keycloak?.hasResourceRole(
      AVAILABLE_ROLE_ENUM.LDC_UI_ACCOUNTING_INVOICE_CORRECTION_POSITIVE
    );

  const { column, direction, loading, data } = tableData;
  const [meta, setMeta] = useState({ page: 1, size: 9, pages: 1 });
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredTableData, setFilteredTableData] =
    useState<TFilteredTableData>({
      ...tableData,
      data: tableData.data?.map((item, index) => {
        return {
          ...item,
          currIndex: index,
        };
      }),
    });
  const totalRecordsNum = useMemo(() => (data && data.length) || 9, [data]);

  useEffect(() => {
    setMeta({
      ...meta,
      pages: Math.ceil(totalRecordsNum / meta.size),
    });
    // eslint-disable-next-line
  }, [totalRecordsNum]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(searchValue), "i");
    const isMatch = (result: any) =>
      re.test(result.referenceId + result.orderNumber + result.packageNumber);
    if (tableData && tableData.data?.length) {
      const newTableData = {
        ...tableData,
        data: tableData.data
          .filter((item) => isMatch(item))
          .map((item, index) => {
            return {
              ...item,
              currIndex: index + 1,
            };
          }),
      };
      setFilteredTableData(newTableData);
      setMeta({
        ...meta,
        pages: Math.ceil(newTableData.data.length / meta.size),
      });
    } else if (!tableData.data) {
      setFilteredTableData({
        ...tableData,
        data: [],
      });
    }
    // eslint-disable-next-line
  }, [tableData, searchValue, selectLine, setTableData]);

  useEffect(() => {
    pageChange(1);
    // eslint-disable-next-line
  }, [searchValue, setTableData]);

  const pageChange = (page: number) => {
    setMeta({
      ...meta,
      page,
    });
  };

  const isValid = (buttonDisable?: boolean) => {
    if (
      tableData &&
      tableData.data &&
      Boolean(tableData.data.length) &&
      isValidFormForCreate()
    ) {
      const selectedItems = tableData.data.filter((item) => item.selected);

      if (!selectedItems.length) {
        if (!buttonDisable) {
          toastWarn(
            "Nie można utworzyć korekty",
            "Korekta nie wprowadza żadnych zmian."
          );
        }
        return false;
      }

      const dataIsValid = selectedItems.some(
        (line) => !validLines(line.lineNumber)
      );

      return !dataIsValid;
    }
    return false;
  };

  const paginateLines = (line: TFilteredLine): boolean => {
    return (
      line.currIndex > (meta.page - 1) * meta.size &&
      line.currIndex <= meta.page * meta.size
    );
  };

  const totalRecordsLabelData: TotalRecords = {
    total: totalRecordsNum,
    filtered: 0,
  };

  const handleSearching = (
    event: React.MouseEvent<HTMLElement>,
    data: SearchProps
  ) => {
    setSearchValue(data.value as string);
    handleSearchChange(event, data);
  };

  return (
    <Modal
      size="fullscreen"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
      onClick={(e: any) => e.stopPropagation()}
      onFocus={(e: any) => e.stopPropagation()}
    >
      <Header
        icon="briefcase"
        content={isDebitNote ? "Dodaj korektę noty" : "Dodaj korektę faktury"}
      />
      <Modal.Content>
        <Form>
          <Form.Dropdown
            placeholder="Wybierz fakturę"
            fluid
            search
            selection
            error={
              (!invoice && changed && "Pole jest wymagane") ||
              (finalInvoice &&
                changed &&
                finalInvoice.correctionBlockade === "EXPIRED_INVOICE" &&
                "Ta faktura nie może być już skorygowana")
            }
            label="Numer faktury sprzedaży, do której ma zostać utworzona korekta"
            value={invoice}
            onChange={changeInvoice}
            onSearchChange={searchInvoice}
            defaultSearchQuery={invoiceIn ? invoiceMask : ""}
            loading={loadingInvoices}
            className={!!invoiceIn ? "user-disable-interaction" : ""}
            options={mapInvoices(isDebitNote ? debitNotes : invoices)}
          ></Form.Dropdown>
          <Form.TextArea
            placeholder="Wypełnij opis"
            label="Opis przyczyny korekty"
            error={
              !description &&
              changed && {
                content: "Pole jest wymagane",
                pointing: "above",
              }
            }
            value={description}
            onChange={inputDescription}
            className="text-area-description-wrapper"
          ></Form.TextArea>
          <Form.Field>
            <label>
              Lista linii z faktury (zaznacz linie do korekty i popraw na
              poprawne wartości)
            </label>
          </Form.Field>
          <Search
            loading={loading}
            open={false}
            onSearchChange={handleSearching}
          />
          <Table sortable basic celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing></Table.HeaderCell>
                <Table.HeaderCell collapsing>Numer linii</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "referenceId" ? direction : null}
                  onClick={() =>
                    setTableData({
                      type: "CHANGE_SORT",
                      column: "referenceId",
                    })
                  }
                >
                  Referencja
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "orderNumber" ? direction : null}
                  onClick={() =>
                    setTableData({
                      type: "CHANGE_SORT",
                      column: "orderNumber",
                    })
                  }
                >
                  Numer Zamówenia
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "packageNumber" ? direction : null}
                  onClick={() =>
                    setTableData({
                      type: "CHANGE_SORT",
                      column: "packageNumber",
                    })
                  }
                >
                  Numer Paczki
                </Table.HeaderCell>
                <Table.HeaderCell collapsing>Poprawna ilość</Table.HeaderCell>
                <Table.HeaderCell collapsing>
                  Poprawna cena netto za sztukę (PLN)
                </Table.HeaderCell>
                <Table.HeaderCell collapsing>
                  Poprawna cena netto za sztukę z rabatem (PLN)
                </Table.HeaderCell>
                {!isDebitNote && (
                  <Table.HeaderCell collapsing>
                    Poprawna stawka VAT
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell collapsing>Rabat</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {filteredTableData.data && filteredTableData.data.length ? (
              <Table.Body>
                {filteredTableData.data
                  .filter((line: TFilteredLine) => paginateLines(line))
                  .map((line: TFilteredLine) => (
                    <Table.Row key={line.lineNumber}>
                      <Table.Cell collapsing>
                        <Checkbox
                          disabled={isChecked(line)}
                          checked={line.selected}
                          onChange={(e, d) => {
                            selectLine(line.lineNumber, e, d);
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell collapsing>{line.lineNumber}</Table.Cell>
                      <Table.Cell>{line.referenceId}</Table.Cell>
                      <Table.Cell>{line.orderNumber}</Table.Cell>
                      <Table.Cell>{line.packageNumber}</Table.Cell>
                      <Table.Cell collapsing>
                        <Popup
                          content={initLineValue(line.lineNumber, "quantity")}
                          trigger={
                            <FormInputNumber
                              min={0}
                              max={
                                !userHaveLdcUiAccountingCorrectionPositive()
                                  ? Number(
                                      String(
                                        initLineValue(
                                          line.lineNumber,
                                          "quantity"
                                        )
                                      ).substring(20)
                                    )
                                  : undefined
                              }
                              placeholder="..."
                              fluid
                              value={line.quantity}
                              error={isInvalidQuantity(line, line.lineNumber)}
                              style={{ minWidth: "150px", opacity: 1 }}
                              onChange={(e, d) =>
                                changeQuantity(line.lineNumber, e, d)
                              }
                              onKeyDown={(
                                e: SyntheticEvent,
                                d: InputOnChangeData
                              ) => changeQuantity(line.lineNumber, e, d)}
                            />
                          }
                        ></Popup>
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Popup
                          content={
                            isPriceBeforeDiscountInvalid(line.lineNumber)
                              ? `${initLineValue(
                                  line.lineNumber,
                                  "unitPriceNetBeforeDiscount"
                                )}, ale ta wartość ceny za sztukę nie może być mniejsza od ceny z rabatem`
                              : initLineValue(
                                  line.lineNumber,
                                  "unitPriceNetBeforeDiscount"
                                )
                          }
                          trigger={
                            <FormInputNumber
                              precision={2}
                              placeholder="..."
                              fluid
                              value={line.unitPriceNetBeforeDiscount}
                              error={isPriceBeforeDiscountInvalid(
                                line.lineNumber
                              )}
                              style={{ minWidth: "150px", opacity: 1 }}
                              onChange={(e, d) =>
                                changeBeforeDiscount(line.lineNumber, e, d)
                              }
                              onKeyDown={(
                                e: SyntheticEvent,
                                d: InputOnChangeData
                              ) => changeBeforeDiscount(line.lineNumber, e, d)}
                            />
                          }
                        ></Popup>
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Popup
                          content={initLineValue(
                            line.lineNumber,
                            "unitPriceNetAfterDiscount"
                          )}
                          trigger={
                            <FormInputNumber
                              min={0.01}
                              max={line.unitPriceNetBeforeDiscount}
                              precision={2}
                              placeholder="..."
                              fluid
                              value={line.unitPriceNetAfterDiscount}
                              error={
                                isInvalidUnitPriceNetAfterDiscount(
                                  line,
                                  line.lineNumber
                                ) && {
                                  content:
                                    "Wartość ceny po rabacie nie może być większa od podstawowej ceny",
                                  pointing: "above",
                                }
                              }
                              style={{ minWidth: "150px", opacity: 1 }}
                              onChange={(e, d) =>
                                changeAfterDiscount(line.lineNumber, e, d)
                              }
                              onKeyDown={(
                                e: SyntheticEvent,
                                d: InputOnChangeData
                              ) => changeAfterDiscount(line.lineNumber, e, d)}
                            />
                          }
                        ></Popup>
                      </Table.Cell>
                      {!isDebitNote && (
                        <Table.Cell collapsing>
                          <Dropdown
                            fluid
                            selection
                            style={{ minWidth: "150px" }}
                            defaultValue={line.taxRate}
                            error={isInvalidVat(line, line.lineNumber)}
                            value={line.taxRate}
                            onChange={(e, d) =>
                              changeVat(line.lineNumber, e, d)
                            }
                            loading={loadingDictionaries}
                            options={mapVat(
                              dictionaries["spare-part-tax-category"]
                            )}
                            noResultsMessage={
                              <FormattedMessage id="app.noResultsMessage" />
                            }
                          />
                        </Table.Cell>
                      )}
                      <Table.Cell collapsing>
                        {line.previewDiscount}%
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            ) : (
              <Fragment></Fragment>
            )}
            <TablePaginationFooter
              meta={meta}
              totalRecords={totalRecordsLabelData}
              pageSizeVisible={true}
              onPageChange={pageChange}
              onPageSizeChange={(size: number) => {}}
            />
          </Table>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Dodaj korektę zerującą"
          icon="minus"
          labelPosition="left"
          loading={creating && isResetCorrection}
          onClick={() =>
            description &&
            description.length &&
            setShownModal(ShownModal.CONFIRM_RESET_CORRECTION)
          }
          disabled={!description || !description.length}
        />
        <Button
          content="Dodaj"
          icon="add"
          labelPosition="left"
          primary
          loading={creating && !isResetCorrection}
          disabled={!isValid(true) || !description.length}
          onClick={() => {
            if (changed) {
              isValid() && setShownModal(ShownModal.CONFIRM_CORRECTION);
            }
            setChanged(true);
          }}
        />
        <Button content="Anuluj" onClick={handleCancel} />
        <ModalConfirm
          modalOpen={shownModal === ShownModal.CONFIRM_RESET_CORRECTION}
          contentText="app.accounting.confirmResetCorrectionOuestion"
          headerIcon="question circle"
          headerText="app.confirmOperation"
          onCancelClick={() => setShownModal(ShownModal.NONE)}
          onConfirmClick={handleCreateRestCorrection}
        />
        <ModalConfirm
          modalOpen={shownModal === ShownModal.CONFIRM_CORRECTION}
          contentText="app.accounting.confirmCorrectionOuestion"
          headerIcon="question circle"
          headerText="app.confirmOperation"
          onCancelClick={() => setShownModal(ShownModal.NONE)}
          onConfirmClick={handleConfirm}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  invoices,
  dictionaries,
}: ApplicationState) => ({
  invoices: invoices.list,
  debitNotes: invoices.debitNotes.list,
  loadingDictionaries: dictionaries.loading,
  loadingInvoices: invoices.loadingInvoices,
  creating: invoices.creating,
  // finalInvoice: invoices.selectedFinalInvoice,
  quantityRedux: invoices.quantityRedux,
  dictionaries,
});

export default connect(
  mapStateToProps,
  null
)(AddInvoiceCorrectionReferenceLine);
